export const styles = (theme) => {
    return {
        detailViewHeaderTitleContainer: {
            display: 'flex',
            marginLeft: "60px",
            backgroundColor: '#F6F6F6',
            width: 'calc(100% - 120px)',
            borderRadius: '4px !important',
            height: "120px",
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '20px !important',
            paddingRight: '20px !important',
        },
        detailViewHeaderTitlesContainer: {
            marginLeft: "0px",
            marginRight: "0px",
            width: 'auto'
        },
        detailViewHeaderTitle: {
            fontWeight: '700',
            fontSize: '27px',
            lineHeight: '32px'
        },
        detailViewHeaderSubtitle: {
            fontWeight: '400',
            fontSize: '20px',
            lineHeight: '28px'
        },
        detailViewHeaderMainActionContainer: {
            display: 'flex',
            flexDirection: 'row',
            marginLeft: "0px",
            marginRight: "0px",
            width: 'auto',
        },
        detailViewHeaderMainActionSelectContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        detailViewHeaderMainActionSelectProgress: {
            margin: '0px',
            marginTop: '45px',
            marginRight: '10px',
            minWidth: '35px !important',
            minHeight: '35px !important',
            width: '35px !important',
            height: '35px !important',
        },
        detailViewHeaderMainActionSelectProgressText: {
            margin: '0px',
            marginTop: '0px',
            minWidth: '35px !important',
            minHeight: '35px !important',
            width: '35px !important',
            height: '35px !important',
        },
        detailViewHeaderMainActionTitle: {
            display: 'flex',
            alignItems: 'center',
            fontWeight: '400',
            fontFamily: "Nunito Sans",
            marginBottom: '10px',
            '& svg': {
                marginRight: '5px !important',
            }
        },
        detailViewHeaderMainActionSelect: {
            '.MuiOutlinedInput-notchedOutline': {
                border: '0px !important',
                outline: '0px !important',
            },
            fontFamily: "Nunito Sans",
            fontWeight: '600',
            height: '45px',
            borderRadius: '8px',
            backgroundColor: 'pink',
            padding:' 12px 20px 12px 20px',
            color: theme.palette.custom.gray1,
            outline: '0px !important',
            border: '0px !important',
            '&:focus': {
                border: '0px !important',
                outline: '0px !important',
            },
            '&:before': {
                border: '0px !important',
                outline: '0px !important',
            },
            '&:after': {
                border: '0px !important',
                outline: '0px !important',
            },
            '&:hover': {
                border: '0px !important',
                outline: '0px !important',
            }
        },
        detailViewHeaderMainActionMenuItem: {
        },
        detailViewHeaderTextColor: (color) =>{
            return {
                color: color,
            };
        },
        detailViewHeaderTextStyles: (styles) => {
            const stylesObj = {}
            if (styles.includes('underline')){
                stylesObj.textDecoration = 'underline';
            }
            return stylesObj;
        },
        detailViewHeaderMainActionMenuHidden: {
            display: 'none',
        }
    };
}
