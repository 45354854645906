import React from 'react';
import { Navigate, useLocation } from 'react-router-dom'

import ContentComponent from './components/componentContent';

/**
 * Todo: This description needs to be filled
 * @returns 
 */
const DefaultLayout = ({setThemeName}) => {
    let { state } = useLocation();
    return (
        <>
            {
                state?.token ? (
                    <ContentComponent setThemeName={setThemeName}/>
                ) : (
                    <Navigate to='/auth/login' />
                )
            }
        </>
    )
}

export default DefaultLayout;