import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Container,
    FormControl,
    Typography,
    TextField,
    Select,
    MenuItem,
    InputAdornment,
    Button
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme } from '@mui/material/styles';
import { styles } from '../../styles/forms/formStyles';
import { handleValues, handleValidations } from '../../../../utils/dataUtils';
import { VisibilityTwoTone, VisibilityOffTwoTone,  } from '@mui/icons-material';
import { getJsonDataFromFile } from '../../../../utils/dataUtils';
import dayjs from 'dayjs';

const Form = ({ fields, values, setValues, setEdited, validations, setValidations}) => {
  let { state } = useLocation();
  const userPermission = state.user?.role;
  const initOptions = {};
  const initVisibility = {};
  for (let field of fields) {
    if ((field.showAdd === undefined || field.showAdd) && field.type === 'select') {
      initOptions[field.field] = field.options;
    }
    if (field.dataType === 'password') {
      initVisibility[field.field] = false;
    }
  }
  const [options, setOptions] = useState(initOptions);
  const [show, setShow] = useState({});
  const [visibilty, setVisibility] = useState(initVisibility);
  const [files, setFiles] = useState([]);
  const theme = useTheme();
  const style = styles(theme);

  const boolOptions = [
    {
      value: true,
      label: 'Yes'
    },
    {
      value: false,
      label: 'No'
    }
  ]

  const handleValueChange = (fieldData, value) => {
    if (setEdited){
      setEdited(true);
    }
    if (validations){
      const thisValidation = handleValidations(fieldData, value);
      setValidations({...validations, [fieldData.field]: thisValidation});
    }

    const newChar = value[value.length - 1];
    const changeType = value.length > values[fieldData.field]?.length ? "add" : "remove";
    handleValues(fieldData, value, values, newChar, changeType);
    setValues({...values});
  }

  const handleConfigOptions = async () => {
    const tempShow = show;
    for (let field of fields) {
      if ((field.showAdd === undefined || field.showAdd) && field.requires) {
        let showfield = true;
        for (let require in field.requires) {
          const requiresValue = values[require];
          if (requiresValue === '' || requiresValue === null || requiresValue === undefined) {
            showfield = false;
          } else {
            if (field.requires[require].filter){
              const tempOptions = options
              const filteredOptions = field.options.filter((option) => {
                return option[field.requires[require].filter.field] === requiresValue
              });
              const filteredOptionsSorted = filteredOptions.sort((a, b) => {
                return a["label"] > b["label"] ? 1 : -1
              })
              tempOptions[field.field] = filteredOptionsSorted;
              setOptions({...tempOptions});
            }
            if (!(!field.requires[require].requiresValues || field.requires[require].requiresValues.includes(requiresValue))){
              showfield = false;
            }
          }
        }
        tempShow[field.field] = showfield;
        const tempValues = values;
        if (!showfield){
          tempValues[field.field] = null;
        } else if (tempValues[field.field] === null)  {
          tempValues[field.field] = '';
        }
        setValues(tempValues);
      } else {
        tempShow[field.field] = true;
      }
    }
    setShow({...tempShow});
  }

  useEffect(() => {
    handleConfigOptions();
    if (!Object.values(values).some(el => el !== null)){
      setFiles([]);
    }
    // eslint-disable-next-line
  }, [values, fields])

  const handlePasswordToggle = (id) => {
    const password = document.getElementById(id);
    if (!visibilty[id]) {
        password.type = 'text';
        visibilty[id] = true;
        setVisibility({...visibilty});
    } else {
        password.type = 'password';
        visibilty[id] = false;
        setVisibility({...visibilty});
    }
  }

  const handleFileChange = async (field, e) => {
    if (setEdited){
      setEdited(true);
    }
    setFiles(e.target.files[0]);
    setValues[field] = e.target.files[0];
    e.preventDefault();
    var files = e.target.files, f = files[0];
    var reader = new FileReader();
    reader.onload = async function (e) {
      const fileData = await getJsonDataFromFile(e, files[0].name)
      values[field] = fileData;
      setValues({...values});
    }
    reader.readAsBinaryString(f);
    if (validations){
      validations[field] = true;
      setValidations({...validations});
    }
  }

  const handleDateChange = (field, newValue) => {
    if (setEdited){
      setEdited(true);
    }
    values[field] = newValue.format('YYYY-MM-DD');
    setValues({...values});
    if (validations){
      validations[field] = true;
      setValidations({...validations});
    }
  }

  return (
    <Container sx={style.formContainer}>
        {fields.map((field, index) => {
        if ((field.showAdd === undefined || field.showAdd) && show[field.field] && (!field.showPermissions || field.showPermissions.includes(userPermission))){
            return (
            <FormControl fullWidth sx={style.formControl} key={field.field}>
                <Typography sx={style.inputLabel}>
                {field.headerName}
                </Typography>
                {field.type === 'text' && (
                <TextField
                    sx={style.textBox}
                    value={values[field.field]}
                    disabled={field.disabled}
                    id={field.field}
                    type={field.dataType}
                    onChange={(e) => handleValueChange(field, e.target.value)}
                    InputProps={ field.dataType === 'password' 
                    ? {
                        endAdornment: (
                            <InputAdornment position="end">
                                {visibilty[field.field]
                                    ? <VisibilityTwoTone sx={style.visibilityIcon} onClick={() => handlePasswordToggle(field.field)} />
                                    : <VisibilityOffTwoTone sx={style.visibilityIcon} onClick={() => handlePasswordToggle(field.field)} />
                                }
                            </InputAdornment>
                        )
                      }
                    : {}
                  }
                />
                )}
                {field.type === 'select' && (
                <Select
                    sx={style.select}
                    id={field.field}
                    value={values[field.field]}
                    disabled={field.disabled}
                    onChange={(e) => handleValueChange(field, e.target.value)}
                    multiple={field.multiple}
                >
                    {options[field.field].map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                        {option.label}
                    </MenuItem>
                    ))}
                </Select>
                )}
                {field.type === 'file' && (
                  <Container>
                    <Typography key={files.name}>{files.name}</Typography>
                    <TextField sx={style.fileUpload} type="file" id="fileUpload" onChange={(event) => handleFileChange(field.field, event)}/>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      onClick={e => {
                        document.getElementById('fileUpload').click();
                      }}
                    >
                      Choose File
                    </Button>
                  </Container>
                )}
                {field.type === 'date' && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={dayjs(values[field.field])}
                      onChange={(newValue) => handleDateChange(field.field, newValue)}
                      sx = {style.datePicker}
                    />
                  </LocalizationProvider>
                )}
                 {field.type === 'bool' && (
                  <Select
                      sx={style.select}
                      id={field.field}
                      value={values[field.field]}
                      disabled={field.disabled}
                      onChange={(e) => handleValueChange(field, e.target.value)}
                  >
                      {boolOptions.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                          {option.label}
                      </MenuItem>
                      ))}
                  </Select>
                )}
            </FormControl>
            )
        } else {
            return null;
        }
        })}
    </Container>
  )
};

export default Form;