import { createTheme } from '@mui/material/styles';
import { populateTheme } from '../utils/themeUtils';


const colors = {
  primary: {
    main: '#e6743a',
    dark: '#e3601e',
    light: '#e68655',
  },
  secondary: {
    main: '#333333',
    dark: '#1a1a1a',
    light: '#545353',
  },
  error: {
    main: '#962424',
    dark: '#6b1a1a',
    light: '#963838',
  },
  warning: {
    main: '#b05d2e',
    dark: '#8e4b1f',
    light: '#b06e3f',
  },
  info: {
    main: '#2196f3',
    dark: '#1e88e5',
    light: '#64b5f6',
  },
  success: {
    main: '#4caf50',
    dark: '#277529',
    light: '#81c784',
  },
  custom: {
    white: '#ffffff',
    borderColor: '#D3D3D3',
    gray1: '#F1F1F1',
    gray2: '#E5E5E5',
    gray3: '#D9D9D9',
    gray4: '#CCCCCC',
    gray5: '#B0B0B0',
    black: '#000000',
    blue: '#1B4380',
    green: '#27935F',
    red: '#FFC5C6',
    pink: '#EDBDF1',
    yellow: '#FFE299',
    lightblue: '#d2f4fa',
    lightpurple: '#dacdfa',
    darkgray: '#666666'
  }
}

const theme = populateTheme(colors);
  
export default createTheme(theme);