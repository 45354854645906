export const styles = (theme) => {
    return {
        mainContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            maxWidth: '600px !important',
        },
        errorText : {
            color: theme.palette.warning.main
        }
    }
}