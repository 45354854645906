import { getAdvisors } from "../../../utils/userConfig";
/**
 * This is the detault columns for Users
 * field - This is the property on the object that wil be displayed within this column
 * headerName - This is the header that will be displayed for the column
 * hideableable: This is a custom field that will determine whether a column will be able to be added to the grid or not (visibility) 
 */
export const getAccountsColumns = async (state, theme) => {
    const advisors = await getAdvisors(state.users);
    return [
        {
            field: 'name',
            headerName: 'Name',
            cellType: 'text',
            showColumn: true,
            showAdd: true,
            required: true,
            type: 'text',
            dataType: 'string',
        },
        {
            field: 'id',
            headerName: 'Account Id',
            cellType: 'text',
            showColumn: true,
            showAdd: true,
            required: true,
            type: 'text',
            dataType: 'string',
        },
        {
            field: 'startDate',
            headerName: 'Start Date',
            cellType: 'text',
            showColumn: true,
            showAdd: true,
            required: true,
            type: 'date',
            dataType: 'string',
        },
        {
            field: 'endDate',
            headerName: 'End Date',
            cellType: 'text',
            showColumn: true,
            showAdd: true,
            required: true,
            type: 'date',
            dataType: 'string',
        },
        {
            field: 'advisorName',
            headerName: 'Advisor',
            cellType: 'text',
            showColumn: true,
            showAdd: false,
            required: true,
            type: 'text',
            dataType: 'string',
        },
        {
            field: 'advisorId',
            headerName: 'Advisor',
            cellType: 'chip',
            showColumn: false,
            showAdd: true,
            required: true,
            type: 'select',
            dataType: 'string',
            options: advisors,
        }
    ];
}