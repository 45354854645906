export const styles = (theme) => {
    return {
        headerBreadCrumbs: {
            display: 'flex',
            marginTop: '20px',
            marginBottom: '20px',
            marginLeft: '40px',
            width: 'auto',
        },
        headerBreadCrumbsFirst: {
            color: theme.palette.secondary.main,
        },
        headerBreadCrumbsSecond: {
            color: theme.palette.primary.main,
        }
    };
};