import React, {useEffect, useState} from 'react';
import { Container, Modal, Typography, TextField, CircularProgress} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { styles } from './styles/modalMD';
import { Close } from '@mui/icons-material';
import Form from './components/forms/form'


const ModalMD = ({
        title,
        type,
        label,
        cancelLabel,
        submitLabel,
        submittingLabel,
        open,
        submitFunction,
        message,
        data,
        setModalOpen,
        formfields,
        thisValue,
        submitType
    }) => {
    const getInitValues = () => {
        const initValues = {};
        if (type === "form"){
            formfields.forEach((field) => {
                if (submitType === "patch"){
                    initValues[field.field] = thisValue[field.field];
                } else {
                    initValues[field.field] = '';
                }
            });
        }
        return initValues;
    }
    const theme = useTheme();
    const style = styles(theme);
    const [thisOpen, setThisOpen] = useState(false);
    const [value, setValue] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [values, setValues] = useState({});
    const [, setEdited] = useState(false);
    const [validations, setValidations] = useState({})

    useEffect(() => {
        setSubmitting(false);
        if (open === title) {
            setValues(getInitValues())
            setThisOpen(true);
            if (type === "text" && thisValue){
                setValue(thisValue);
            } else {
                setValue("");
            }
        } else {
            setThisOpen(false);
        }
        // eslint-disable-next-line
    }, [open])

    const handleModalClose = () => {
        setThisOpen(false)
        setModalOpen(false)
    }

    const processMessage = (message) => {
        let dataTry = false
        let finalMessage = message.replace(/{data.(.*?)}/g, (match, field) => {
            if (data[field]){
                dataTry = true
            }
            return data[field];
        });
        if (!dataTry){
            finalMessage = message.replace(/{thisValue.(.*?)}/g, (match, field) => {
                return thisValue[field];
            });
        }
        return finalMessage;
    }

    const handleModalSubmit = async (value) => {
        setSubmitting(true);
        if (type === "form"){
            submitFunction(values);
        } else {
            submitFunction(value);
        }
    }

    return (
        <>
            {values &&
                <Modal
                    open={thisOpen}
                    onClose={null}
                    >
                    <Container sx={style.detailViewContainerModal}>
                        <Container sx={style.detailViewContainerModalHeader}>
                            <Typography sx={style.detailViewContainerModalHeaderTitle}>
                            {title}
                            </Typography>
                            <Close sx={style.detailViewContainerModalHeaderClose} onClick={() => handleModalClose()} />
                        </Container>
                        <Container sx={style.detailViewContainerModalBody}>
                            <Typography sx={style.detailViewContainerModalBodyLabel}>
                            {label}
                            </Typography>
                            {type === "textArea" && (
                                <TextField 
                                    multiline
                                    value={value}
                                    sx={style.detailViewContainerModalBodyTextArea}
                                    onChange={(e) => setValue(e.target.value)}
                                />
                            )}
                            {type === "text" && (
                                <Typography>{processMessage(message)}</Typography>
                            )}
                            {type === "form" && (
                                <Form 
                                    fields={formfields}
                                    values={values}
                                    setValues={setValues}
                                    setEdited={setEdited}
                                    validations={validations}
                                    setValidations={setValidations}
                                />
                            )}
                        </Container>
                        <Container sx={style.detailViewContainerModalFooter}>
                            {cancelLabel 
                                && <Container 
                                        sx={style.detailViewContainerModalFooterCancelButton}
                                        onClick={() => handleModalClose()}
                                    >
                                        {cancelLabel}
                                    </Container>
                            }
                            <Container 
                                sx={style.detailViewContainerModalFooterSubmitButton}
                                onClick={() => handleModalSubmit(value)}
                            >
                                {submitting && submittingLabel ? submittingLabel : submitLabel}
                                {submitting && <CircularProgress sx={style.detailViewContainerModalFooterSubmitProgress}/>}
                            </Container>
                        </Container>   
                    </Container>
                </Modal>
            }
        </>
    );

};

export default ModalMD;