import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Card from '../core/components/cards/card';
import { getCardConfig } from './configs/positionFileConfigs';
import { api } from '../../api/index';

const PositionFilePage = () => {
    const navigate = useNavigate()
    let { state } = useLocation();
    const theme = useTheme();
    const defaultValues = {date: ""};
    const defaultValidations = {date: false};

    const [values, setValues] = useState(defaultValues);
    const [validations, setValidations] = useState(defaultValidations);
    const [edited, setEdited] = useState(false);
    const [config, setConfig] = useState({});
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const config = await getCardConfig(state);
            setConfig(config);
        }
        fetchData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
      const disabled = Object.values(validations).includes(false);
      setDisabled(disabled);
      // eslint-disable-next-line
    }, [validations]);


    const handleExport = async () => {
      setErrorMsg('')
      setLoading(true);
      setError(false)
      const results = await api("post", values, "positionfileexport", state, navigate);
      if (results.status === 200) {
        if(results.data.length > 0){
          const csv = convertToCSV(results.data)
          downloadCSV(csv, `Master Position File`)
        }
        else {
          setErrorMsg(' - No Data found for selected Account')
          setError(true)
          setDisabled(false);
        }
      } else {
        setError(true)
        setDisabled(false);
      }
      setLoading(false);
    }

    const convertToCSV = (data) => {
      const array = [Object.keys(data[0])].concat(data);
    
      return array.map(row => {
        return Object.values(row).map(value => {
          return typeof value === 'string' ? JSON.stringify(value) : value;
        }).toString();
      }).join('\n');
    };

    const downloadCSV = (csv, filename) => {
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) { // feature detection
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };
    
    return (
      <Container sx={{display : 'flex', flexDirection : 'column', justifyContent : 'center', alignItems : 'center', height : '100vh', maxWidth : '600px !important'}}>
          <Card 
              key={config.title}
              title={config.title}
              fields={config.fields}
              data={{}}
              values={values}
              setValues={setValues}
              validations={validations}
              setValidations={setValidations}
              bodyType={config.bodyType}
              setEdited={setEdited}
              checkListValues={{}}
          />
          {error &&
            <Typography sx={{color : theme.palette.warning.main}}>
              Error Exporting File {errorMsg}
            </Typography>
          }
          {edited &&
            <Button 
              variant='contained'
              disabled={disabled}
              onClick={() => handleExport()}>
                {loading ? "Exporting..." : "Export"}
            </Button>
          }
      </Container>
    )
}

export default PositionFilePage;