import React, { useState } from 'react';
import { Container, Button, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { styles } from './styles/detailsView/detailView';
import DetailViewHeader from './components/detailsView/detailViewHeader';
import DetailViewTabs from './components/detailsView/detailViewTabs';
import DetailInfoCardPage from './components/detailsView/detailInfoCardPage.js';
import ModalMD from './modalMD';
import { api } from '../../api';

const DetailsView = ({
        titleConfig,
        type,
        tabs,
        actionItems,
        headerConfig,
        cards
    }) => {
    const theme = useTheme();
    const style = styles(theme);

    let { state } = useLocation();
    const data = state.record;
    const navigate = useNavigate();
    const [open, setOpen] = useState("closed");
    const [updating, setUpdating] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalConfig, setmodalConfig] = useState({});
    const [edited, setEdited] = useState(false)

    const getDefaultValues = () => {
        const initValues = {};
        for (let field in data){
            initValues[field] = data[field]
        }
        for (let card in cards){
            for (let field in cards[card].fields){
                if (!(cards[card].fields[field].field in initValues)){
                    initValues[cards[card].fields[field].field] = ""
                }
            }
        }
        return initValues
    }

    const [values, setValues] = useState(getDefaultValues());
    const [validations, setValidations] = useState({});

    const handleOpen = (open) => {
        setOpen(open);
    }

    const handleModalSubmit = async (value) => {
        await modalConfig.submitFunction(modalConfig.field, value, modalConfig.saveToDb, modalConfig.changeValue, modalConfig.changeField);
        setModalOpen(false);
    }

    const handleModalOpen = (modalConfigObj) => {
        setmodalConfig(modalConfigObj);
        setModalOpen(modalConfigObj.title);
    }

    const handleSaveClick = async() => {
        if (!updating){
            setUpdating(true);
            let tempData = {...data}
            for (const field in values){
                tempData[field] = values[field]
            }
            const results = await api("patch", tempData, type, state, navigate)
            if (results.status === 200){
                state.record = tempData
                const allData = state[type.toLowerCase()]
                allData[state[type.toLowerCase()].findIndex((item) => item.id === tempData.id)] = tempData
                state[type.toLowerCase()] = allData;
                setEdited(false)
                navigate(".", { replace: true, state: state });
            } else {
                state.record = {...data}
                navigate(".", { replace: true, state: state });
            }
            setUpdating(false);
        }
    }

    const handleCancelClick = () => {
        const tempValues = getDefaultValues();
        setValues({...tempValues});
        setEdited(false);
    }

    return (
        <Container sx={style.detailViewContainer}>
            <Container sx={[style.detailViewLeftContainer, open === "max" && style.detailViewLeftContainerOpen]}>
                <DetailViewHeader 
                    data={data}
                    titleConfig={titleConfig}
                    type={type}
                    actionItems={actionItems}
                    headerConfig={headerConfig}
                    updating={updating}
                    setUpdating={setUpdating}
                    handleModalOpen={handleModalOpen}
                />
                {cards
                    ? <DetailInfoCardPage 
                        data={data}
                        cards={cards}
                        values={values}
                        setValues={setValues}
                        validations={validations}
                        setValidations={setValidations}
                        setEdited={setEdited}
                    />
                    : <DetailViewTabs 
                        data={data}
                        tabs={tabs}
                        handleOpen={handleOpen}
                        updating={updating}
                        setUpdating={setUpdating}
                        handleModalOpen={handleModalOpen}
                    />
                }
                {cards && edited &&
                <Container sx={style.detailViewButtonContainer}>
                    <Button sx={style.detailViewButtonCancel} onClick={handleCancelClick}>Cancel</Button>
                    <Button sx={style.detailViewButtonSave} onClick={handleSaveClick} disabled={Object.values(validations).includes(false)} >
                        {updating ? "Saving" : "Save"}
                        {updating && <CircularProgress sx={style.detailViewButtonProgress}/>}
                    </Button>
                </Container>
            }
            </Container>
            <ModalMD 
                title={modalConfig.title}
                type={modalConfig.type}
                label={modalConfig.label}
                cancelLabel={modalConfig.cancelLabel}
                submitLabel={modalConfig.submitLabel}
                submittingLabel={modalConfig.submittingLabel}
                open={modalOpen}
                submitFunction={handleModalSubmit}
                message={modalConfig.message}
                data={data}
                setModalOpen={setModalOpen}
            />
        </Container>
    );

};

export default DetailsView;
