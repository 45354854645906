import React from 'react';
import { Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styles } from '../../styles/cards/checkListStyles';
import { Check, Close } from '@mui/icons-material';

const CheckList = ({fields, checkListValues}) => {

    const theme = useTheme();
    const style = styles(theme);
    return (
        <Container>
            {fields.map((field, index) => {
                return (
                    <Container sx={style.itemContainer} key={index}>
                        {checkListValues[field.field] === true
                            ? <Check sx={style.checkIcon}/> 
                            : <Close sx={style.closeIcon}/>
                        }
                        <Typography style={style.itemText}>{field.title}</Typography>
                    </Container>
                );
            })}
        </Container>
    );
};

export default CheckList;