export const styles = (theme) => {
    return {
        itemContainer: {
            display: 'flex',
            flexDirection: 'row',
            padding: '20px !important',
            borderBottom: `1px solid ${theme.palette.custom.borderColor}`,
        },
        checkIcon: {
            color: theme.palette.success.main,
            marginRight: '10px'
        },
        closeIcon: {
            color: theme.palette.error.main,
            marginRight: '10px'
        },
    }
}