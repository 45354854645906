export const styles = (theme) => {
    return {
        cardContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 'calc(100% - 80px)',
            marginTop: '10px',
            marginLeft: '40px',
            paddingTop: '20px !important',
        },
        cardContainerLeft: {
            display: 'flex',
            flexDirection: 'column',
            width: '48%',
            padding: '0px !important',
            margin: '0px !important',
        },
        cardContainerRight: {
            display: 'flex',
            flexDirection: 'column',
            width: '48%',
            padding: '0px !important',
            margin: '0px !important',
        },
    };
};