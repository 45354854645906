import { CircularProgress, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../api/index";
import DataTable from "./components/dataTableMD/dataTable.js";
import DataGridFilters from "./components/dataTableMD/dataTableFilters.js";
import DataGridFooter from "./components/dataTableMD/dataTableFooter.js";
import DataGridHeader from "./components/dataTableMD/dataTableHeader.js";
import DataTableSearch from "./components/dataTableMD/dataTableSearch.js";
import { styles } from "./styles/dataTableMD/dataTableMD.jsx";

const DataTableMD = ({
  title,
  filters,
  search,
  toggles,
  columns,
  addButton,
  addSuccessMessage,
  sort,
  bulkAddButton,
  modalConfigObj,
}) => {
  const theme = useTheme();
  const style = styles(theme);
  let { state } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentOrder, setCurrentOrder] = useState("asc");
  const [sortField, setSortField] = useState("");
  const [currentPageData, setCurrentPageData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [activeToggles, setActiveToggles] = useState(toggles);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes, setPageSizes] = useState([pageSize, 25, 50, 100, 200]);
  const [totalPages, setTotalPages] = useState(0);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const fetchingData = useRef(false)

  const sortData = (thisData, field, order) => {
    let sameField = false;
    if (sortField === field) {
      sameField = true;
    }
    setSortField(field);
    let preSortData = thisData;
    let thisOrder = order;
    if (!preSortData) {
      preSortData = data;
    }
    if (thisOrder) {
      setCurrentOrder(thisOrder);
    } else {
      thisOrder = currentOrder;
    }
    if (sameField) {
      if (thisOrder === "asc") {
        thisOrder = "desc";
        setCurrentOrder("desc");
      } else {
        thisOrder = "asc";
        setCurrentOrder("asc");
      }
    }
    preSortData.sort((a, b) => {
      if (thisOrder === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });
    if (!thisData) {
      setData([...preSortData]);
    }
    return preSortData;
  };

  const fetchData = async () => {
    fetchingData.current = true
    const response = await api("get", {}, title, state, navigate);
    if (response.status === 200) {
      if (sort) {
        response.data = sortData(response.data, sort.field, sort.order);
      }
      setData(response.data);
      setAllData(response.data);
      setLoaded(true);
    }
    fetchingData.current = false
  };

  const processFilter = async (fields, values) => {
    const tempFilterData = filterData;
    if (fields && values) {
      for (let field in fields) {
        if (activeToggles) {
          if (activeToggles.field === fields[field]) {
            const tempActiveToggles = activeToggles;
            tempActiveToggles.active = values;
            setActiveToggles(tempActiveToggles);
          }
        }
        if (
          !values ||
          values.includes("All") ||
          values.length === 0 ||
          values === ""
        ) {
          delete tempFilterData[fields[field]];
        } else {
          tempFilterData[fields[field]] = values;
        }
        setFilterData(tempFilterData);
      }
    } else {
      if (fields) {
        for (let field in fields) {
          delete tempFilterData[fields[field]];
        }
      }
    }

    const filteredData = []
    for (let item in allData) {
        let foundInSearch = false
        for (let filter in tempFilterData) {
            if (allData[item][filter]){
                if (allData[item][filter].constructor === Array) {
                    if (tempFilterData[filter].some(r=> [item][filter].some(f => f.substring(0, r.length).toLowerCase() === r.toLowerCase()))) {
                        foundInSearch = true
                    }
                } else if (allData[item][filter].substring(0, tempFilterData[filter].length).toLowerCase() === tempFilterData[filter].toLowerCase()){
                    foundInSearch = true
                }
            }
        }
        if (foundInSearch) {
            filteredData.push(allData[item])
        }
    }
    if (Object.keys(tempFilterData).length === 0) {
      setData([...allData]);
    } else {
      setData([...filteredData]);
    }
  };

  const handleRowClick = async (id) => {
    const formattedTitle = title.replace(/s$/, "");
    const lowercaseTitle = formattedTitle.toLowerCase();
    const thisRecord = data.filter((record) => record.id === id);
    state.record = thisRecord[0];
    navigate(`/${lowercaseTitle}/${id}`, { state: state });
  };

  useEffect(() => {
    processFilter();
    // eslint-disable-next-line
  }, [allData]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const widthObject = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      setWindowSize({ ...widthObject });
    });
    if(!fetchingData.current){
      fetchData();
    }
    

    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    const cellHeight = 75;
    const pageSize = parseInt((windowSize.height - 400) / cellHeight);
    setPageSize(pageSize);
    setPageSizes([pageSize, 25, 50, 100, 200]);
    setPage(0);
  }, [windowSize]);

  useEffect(() => {
    const tempTotalPages = Math.ceil(data.length / pageSize);
    setTotalPages(tempTotalPages);
    if (data.length > pageSize && pageSize !== 0) {
      const startIndex = page * pageSize;
      setCurrentPageData([...data.slice(startIndex, startIndex + pageSize)]);
    } else {
      setCurrentPageData([...data]);
    }
  }, [pageSize, data, page]);

  return (
    <Container sx={style.DataGridStyleContainer}>
      <DataGridHeader
        title={title}
        toggles={activeToggles}
        processFilter={processFilter}
        addButton={addButton}
        columns={columns}
        addSuccessMessage={addSuccessMessage}
        allData={allData}
        setAllData={setAllData}
        bulkAddButton={bulkAddButton}
        modalConfigObj={modalConfigObj}
      />

      {filters?.length > 0 && (
        <DataGridFilters filters={filters} processFilter={processFilter} />
      )}
      {search && (
        <DataTableSearch
          searchFields={search.fields}
          processFilter={processFilter}
        />
      )}
      {loaded ? (
        <DataTable
          data={currentPageData}
          fields={columns}
          handleRowClick={handleRowClick}
          sortData={sortData}
          currentOrder={currentOrder}
          sortField={sortField}
        />
      ) : (
        <Container sx={style.dataTableLoadingContainer}>
          <CircularProgress />
        </Container>
      )}

      <DataGridFooter
        page={page}
        pageSize={pageSize}
        pageSizes={pageSizes}
        setPage={setPage}
        setPageSize={setPageSize}
        totalPages={totalPages}
      />
    </Container>
  );
};

export default DataTableMD;
