import React, { useState, useEffect} from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import publicRoutes from './routes/publicRoutes';
import privateRoutes from './routes/privateRoutes';
import lightTheme from './theme/light';
import darkTheme from './theme/dark';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

/***
 * This component is the main application component responsible for routing within the application.
 * For Routes:
 * NB: Please place routes in alphabetical order according to path.
 * NB: Numbers precede letters.
 */
const App = () => {
  const [themeName, setThemeName] = useState("light");
  const [thisTheme, setThisTheme] = useState(lightTheme);

  useEffect(() => {
    setThisTheme(themeName === "light" ? lightTheme : darkTheme);
  }, [themeName]);
  const router = createBrowserRouter([...privateRoutes(setThemeName), ...publicRoutes]);
  
  return (
    <ThemeProvider theme={thisTheme}>
      <CssBaseline />
      <RouterProvider router={router} setThemeName={setThemeName} themeName={themeName}/>
    </ThemeProvider>
  );
};

export default App;