import { httpEvent } from '../utils/utilsApi';

/**
 * This is the main API function for the application.
 * This function will handle all API calls for the application.
 * @param {string} apiType - The type of API call to make. options are: 'auth', 'get', 'post', 'patch', 'delete'
 * @param {object} data - The data to send to the API
 * @param {string} type - The type of API call to make. options are: "accounts", "users", "generateReport
 *
 * @returns {object} - The response from the API
 */
export const api = async (apiType, data, type, state, navigate) => {
    const apiName = apiType === 'auth' ? 'auth' : 'crud';
    if (apiType === 'auth') {
        apiType = 'post';
    }
    return await httpEvent(apiName, apiType.toUpperCase(), type, data, state, navigate)
}