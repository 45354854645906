export const styles = (theme) => {
  return {
    modalContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: 5,
      borderRadius: '5px',
      width: '794px',
      overflow: 'hidden',
      padding: '0px !important',
    },
    modalHeader: {
      padding: '15px',
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    modalBodyContainer: {
      padding: '15px',
      backgroundColor: theme.palette.custom.white,
    },
    closeIcon: {
      fontSize: '15px',
      cursor: 'pointer'
    },
    footerContainer: {
      backgroundColor: theme.palette.custom.white,
      padding: '15px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    closeButton: {
      color: theme.palette.common.white,
      width: '100%'
    },
    cancelButton: {
      backgroundColor: theme.palette.custom.gray1,
      color: theme.palette.error.main,
      marginRight: '20px',
      '&:hover': {
        backgroundColor: theme.palette.custom.gray2,
      }
    },
    addButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.custom.white,
      '&:hover': {
          backgroundColor: theme.palette.primary.light,
      }
    },
    formBody: {
      border: `1px solid ${theme.palette.custom.borderColor}`,
      borderRadius: 1
    },
    inputFontColor: {
      color: theme.palette.custom.textColor1
    }
  };
};
