

export const getCardConfig = async(data) => {
    return [
        {
            column: 1,
            title: "Personal Information",
            bodyType: "form",
            fields: [
                {
                    field: 'firstName',
                    headerName: 'First Name',
                    cellType: 'text',
                    required: true,
                    type: 'text',
                    dataType: 'string',
                },
                {
                    field: 'lastName',
                    headerName: 'Last Name',
                    cellType: 'text',
                    required: true,
                    type: 'text',
                    dataType: 'string',
                },
                {
                    field: 'email',
                    headerName: 'Email',
                    cellType: 'text',
                    disabled: true,
                    required: true,
                    type: 'text',
                    dataType: 'email',
                },
                {
                    field: 'role',
                    headerName: 'Role',
                    disabled: true,
                    required: true,
                    type: 'text',
                    dataType: 'string',
                }
            ]
        }
    ]
}