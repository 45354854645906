import axios from 'axios';

/**
 * Todo: This description needs to be filled
 * @param {*} api 
 * @param {*} method 
 * @param {*} type 
 * @param {*} data 
 * @returns 
 */
export const httpEvent = async (api, method, type, data, state, navigate) => {
    const headers = {};
    let baseUrl = process.env.REACT_APP_apiBaseUrl;

    if (api !== "auth") {
        const token = state?.token;
        headers["x-api-key"] = token;
    } else {
        baseUrl = process.env.REACT_APP_baseAuthUrl;
    }

    if (type === "changePassword") {
        const token = state?.token;
        headers["x-api-key"] = token;
    }

    const url = `${baseUrl}${api}/${type}/`;
    const requestObj = { method: method, url: url, headers: headers };

    for (let item in data) {
        if (data[item] === null) {
            delete data[item];
        };
    }

    if (method === "GET") {
        requestObj.params = data;
    } else {
        requestObj.data = data;
    };
    try {
        const responseData = await axios(requestObj);
        return responseData;
    } catch (error) {
        if (error.response?.data?.message === "authorization has expired, please login again") {
            localStorage.removeItem("accessToken");
            navigate("/")
            return {"status": "success"}
        } else {
            return {
                error: true,
                status: error?.response?.status ? error?.response?.status : 500,
                statusText: error?.response?.statusText ? error?.response?.statusText : "Unkown Error",
                data: error?.response?.data ? error?.response?.data : {error: "Unkown Error"}
            };
        };
    };
};

export const removedNamesForUpdate = async (record) => {
    const fieldNames = [
        "schoolName",
        "districtName",
        "assignedUsersNames"
    ];
    for (let field of fieldNames) {
        if (field in record) {
            delete record[field];
        };
    }
    return record;
}