import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import DataTableMD from '../core/dataTableMD';
import { getUsersColumns } from './configs/usersDataTableConfig';
/*
 * This generates the Users Page for the application
 * @param {*} props These are the properties required for the Users page (user, etc)
 * @returns This returns a generated Users page for the user
 */
const UsersPage = () => {
    const theme = useTheme();
    const [columns, setColumns] = useState([])

    useEffect(() => {
        const getFilters = async () => {
            const columns = await getUsersColumns(theme)
            setColumns(columns)
        }
        getFilters()
        // eslint-disable-next-line
    }, [])

    const addSuccessMessage = "The new user has been successfully added to the system. "
    return (
        <DataTableMD
            title="Users"
            filters = {{}}
            search={{fields: ["firstName", "lastName", "email"]}}
            columns = {columns}
            addButton={true}
            addSuccessMessage={addSuccessMessage}
            sort={{field: 'firstName', order: 'asc'}}
        />
    );
};

export default UsersPage;