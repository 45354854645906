export const styles = (theme) => {
    return {
        dataTableFooterContainer: {
            height: '60px',
            margin: "0px",
            display: 'flex',
            backgroundColor: '#FFF',
            alignItems: "center",
            minWidth: '100%',
            borderRadius: '0px 0px 4px 4px',
            border: '1px solid #F0F0F0',
            borderTop: "none",
        },
        dataTableFooterLeftContainer: {
            margin: "0px",
            display: 'flex',
            alignItems: "center",
            marginLeft: '10px',
        },
        dataTablePageSizeSelect: {
            marginLeft: '10px',
            marginRight: '20px',
            height: '40px',
        },
        dataTablePageSizeTextField: {
            marginLeft: '5px',
            width: '100px',
        },
        dataTablePageButton: {
            backgroundColor: '#FFF',
            color: '#262626',
            marginLeft: '5px',
            minWidth: '30px',
            paddingLeft: '4px',
            paddingRight: '4px',
            boxShadow: "none",
            border: "1px solid #F0F0F0",
            '&:disabled': {
                backgroundColor: '#FFF',
                color: '#D9D9D9',
            },
            '&:hover': {
                backgroundColor: '#FFF',
                color: '#262626',
                boxShadow: "none",
            }
        },
        dataTablePageButtonIcon: {
            width: '20px',
        },
        dataTablePageButtonText: {
            paddingLeft: '6px',
            paddingRight: '6px',
        },
        dataTablePageMoreHoriz: {
            backgroundColor: 'transparent !important',
            minWidth: '30px',
            marginLeft: '0px',
            marginRight: '0px',
            color: '#262626',
        },
        dataTableFooterRightContainer: {
            margin: "0px",
            display: 'flex',
            float: 'right',
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: '10px',
        },
    };
};
