import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Container,
    Typography,
    Button,
    Box
} from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import { styles} from '../../styles/dataTableMD/dataTableHeader';
import AddModal from '../modal/modal';
import { api } from '../../../../api';
import { getTitleString } from '../../../../utils/dataUtils';
import ModalMD from '../../modalMD.js'

const DataGridHeader = ({title, toggles, processFilter, addButton, columns, addSuccessMessage, allData, setAllData, bulkAddButton, modalConfigObj}) => {
    const navigate = useNavigate()
    const theme = useTheme();
    const style = styles(theme);
    let { state } = useLocation();
    const getInitValues = () => {
        const initValues = {};
        columns.forEach((column) => {
            if (column.showAdd) {
                initValues[column.field] = '';
            }
        });
        return initValues;
    }
    const [submit, setSubmit] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalConfig, setmodalConfig] = useState({});
    const [values, setValues] = useState(getInitValues());
    const [dataVar, ] = useState([]);

    const handleModalOpen = (modalConfigObj, value) => {
        if (value){
          modalConfigObj.value = value;
        }
        setmodalConfig(modalConfigObj);
        setModalOpen(modalConfigObj.title);
    }
  
    const handleSaveModal = async(value) => {
        const results = await api("post", {data: value.file}, title, state, navigate)
        if (results.status === 200) {
            setSubmit(true);
            const tempAllData = allData;
            tempAllData.push(results.data);
            setAllData([...tempAllData]);
            state[title.toLowerCase()] = [...tempAllData];
            navigate(".", { replace: true, state: state });
        }
      
    }
  
    const handleModalSubmit = async (value) => {
      await handleSaveModal(value);
      setModalOpen(false);
    }


    const handleOpen = () => {
        setOpen(true);
        setValues(getInitValues());
    }

    const handleClose = () => {
        setOpen(false);
        setSubmit(false);
    }

    const handleSubmit = async (values, setAdding) => {
        setAdding(true);
        for (let value in values) {
            if (values[value] === '') {
                delete values[value];
            }
        }
        const results = await api("post", values, title, state, navigate)
        if (results.status === 200) {
            setSubmit(true);
            setAdding(false);
            const tempAllData = allData;
            tempAllData.push(results.data);
            setAllData([...tempAllData]);
            state[title.toLowerCase()] = [...tempAllData];
            navigate(".", { replace: true, state: state });
        } else {
            setAdding(false);
        }
    }

    const handleToggleClick = (toggleOption) => {
        processFilter(toggles.field, [toggleOption])
    }

    return (
        <Container sx={style.DataGridHeaderContainer}>
            <Typography sx={style.DataGridHeaderTitle} variant='h4'>
                {title}
            </Typography>
            <Box>
                {bulkAddButton && (
                    <Button 
                        size='medium'
                        variant='contained'
                        onClick={() => handleModalOpen(modalConfigObj.bulk, "test")}
                        endIcon={<AddRounded />}
                        sx={style.bulkButton}
                    >
                        {`Add bulk ${getTitleString(title)}S`}
                    </Button>
                )}
                {addButton && (
                    <Button 
                        size='medium'
                        variant='contained'
                        onClick={() => handleOpen()}
                        endIcon={<AddRounded />}
                    >
                        {`Add A New ${getTitleString(title)}`}
                    </Button>
                )}
                
                {toggles && toggles.options.map((toggleOption, index) => (
                    <Button 
                        sx={[style.DataGridHeaderButton, toggles.active && toggles.active.includes(toggleOption) ? style.DataGridHeaderButtonActive : style.DataGridHeaderButtonInactive]}
                        size='medium'
                        variant="contained"
                        key={index}
                        onClick={e => handleToggleClick(toggleOption)}
                    >
                        {toggleOption}
                    </Button>
                ))}
            </Box>
            {addButton && (
                <AddModal
                    open={open}
                    submit={submit}
                    handleClose={handleClose}
                    title={getTitleString(title)}
                    handleSubmit={handleSubmit}
                    columns={columns}
                    addSuccessMessage={addSuccessMessage}
                    values={values}
                    setValues={setValues}
                />
            )}
            {bulkAddButton && (
                <ModalMD
                    title={modalConfig.title}
                    type={modalConfig.type}
                    label={modalConfig.label}
                    cancelLabel={modalConfig.cancelLabel}
                    submitLabel={modalConfig.submitLabel}
                    submittingLabel={modalConfig.submittingLabel}
                    open={modalOpen}
                    submitFunction={handleModalSubmit}
                    message={modalConfig.message}
                    data={dataVar}
                    setModalOpen={setModalOpen}
                    formfields={modalConfig.form?.fields}
                    thisValue={modalConfig.value}
                    submitType={modalConfig.submitType}
                />
            )}
        </Container>
    );

};

export default DataGridHeader;