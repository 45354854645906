export const getCardConfig = async(data) => {
    return [
        {
            column: 1,
            title: "Upload Data",
            bodyType: "form",
            fields: [
                {
                    field: 'file',
                    headerName: 'File',
                    required: true,
                    type: 'file',
                    dataType: 'string',
                },
            ]
        }
    ]
}