import React from 'react';
import { Outlet } from 'react-router-dom'
import { Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { styles } from '../styles/layoutStyles';
import { TopBarComponent } from './componentTopbar';

const ContentComponent = () => {
    const theme = useTheme();
    const style = styles(theme);
    return (
        <Container sx={style.mainContainer}>
            <TopBarComponent/>
            <Container sx={style.contentContainer}>
                <Outlet />
            </Container>
        </Container>
    );
};

export default ContentComponent
