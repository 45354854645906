
import ChangePasswordTab from "../components/changePasswordTab"
import MyAccountTab from "../components/myAccountTab"


export const getDetailViewConfig = async(data) => {
    return {
        titleConfig: {
            breadcrumbs: false,
            breadcrumbsReplacment: 'Account Settings',
            title: 'My Account ',
        },
        type: 'users',
        tabs: [
            {title: 'My Account', component:MyAccountTab},
            {title: 'Change Password', component:ChangePasswordTab},
        ],

    }
}