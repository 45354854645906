import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Typography, Chip, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import DetailViewHeaderTitle from './detailViewHeaderTitle';
import { styles } from '../../styles/detailsView/detailViewHeader';

import HeaderBreadCrumbs from '../headerBreadCrumbs';

import { api } from '../../../../api';

const DetailViewHeader = ({
        data,
        titleConfig,
        type,
        actionItems,
        headerConfig,
        updating,
        setUpdating,
        handleModalOpen
    }) => {
    const theme = useTheme();
    const style = styles(theme);
    let { state } = useLocation();
    const navigate = useNavigate();
    const [rightButtonUpdating, setRightButtonUpdating] = useState(false)
    const [rightButtonConfig, setRightButtonConfig] = useState({
        backgroundColor: headerConfig?.rightButton?.backgroundColor[data[headerConfig.rightButton.field]],
        title: headerConfig?.rightButton?.title[data[headerConfig.rightButton.field]],
        iconAfter: headerConfig?.rightButton?.iconAfter[data[headerConfig.rightButton.field]],
    })

    useEffect(() => {
        setRightButtonConfig({
            backgroundColor: headerConfig?.rightButton?.backgroundColor[data[headerConfig.rightButton.field]],
            title: headerConfig?.rightButton?.title[data[headerConfig.rightButton.field]],
            iconAfter: headerConfig?.rightButton?.iconAfter[data[headerConfig.rightButton.field]],
        })
        // eslint-disable-next-line
    }, [data])

    const typeDisplay = type.charAt(0).toUpperCase() + type.substring(1, type.length - 1);

    const handleRightButtonClick = async (value) => {
        if (!updating){
            setRightButtonUpdating(true)
            setUpdating(true)
            const tempData = {...data}
            tempData[headerConfig.rightButton.field] = headerConfig.rightButton.value[value]
            const results = await api("patch", tempData, type, state, navigate)
            if (results.status === 200){
                setRightButtonUpdating(false)
            } else {
                tempData[headerConfig.rightButton.field] = data[headerConfig.rightButton.field]
                setRightButtonUpdating(false)
            }
            state.record = tempData
            navigate(".", { replace: true, state: state });
            setUpdating(false)
        }
    }

    const processFieldVariables = (field) => {
        if (field.includes("{") && field.includes("}")) {
            const fieldVariables = field.match(/{(.*?)}/g);
            fieldVariables.forEach(variable => {
                const variableName = variable.substring(1, variable.length - 1);
                field = field.replace(variable, data[variableName]);
            });
        } else {
            field = data[field];
        }
        return field;
    }

    return (
        <Container sx={style.detailViewHeaderContainer}>
            {titleConfig?.breadcrumbs
                ? <HeaderBreadCrumbs 
                    first={typeDisplay + "s"}
                    last={typeDisplay + " Information"}
                />
                : titleConfig?.breadcrumbsReplacment && (
                    <Typography sx={style.breadcrumbReplacement}>
                        {titleConfig.breadcrumbsReplacment}
                    </Typography>
                )
            }
            <DetailViewHeaderTitle 
                data={data}
                titleConfig={titleConfig}
                type={type}
                updating={updating}
                setUpdating={setUpdating}
                handleModalOpen={handleModalOpen}
                processFieldVariables={processFieldVariables}
            />
            <Container sx={style.detailViewHeaderActionsContainer}>
                <Container sx={style.detailViewHeaderRightButtonAfterContainer}>
                    {rightButtonUpdating && (
                        <CircularProgress sx={style.detailViewHeaderRightButtonAfterProgress}/>
                    )}
                    {headerConfig?.rightButton && (
                        <Chip
                            sx={[style.detailViewHeaderRightButton(rightButtonConfig.backgroundColor)]}
                            onClick={() => handleRightButtonClick(data[headerConfig.rightButton.field])}
                            label={[
                                rightButtonConfig.title, 
                                <rightButtonConfig.iconAfter key={2} sx={style.detailViewHeaderRightButtonAfterIcon}/>
                            ]}
                        />
                    )}
                </Container>
            </Container>
        </Container>
    );
};

export default DetailViewHeader;