import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Button, CircularProgress } from '@mui/material';
import DetailInfoCardPage from '../../core/components/detailsView/detailInfoCardPage.js';
import { getCardConfig } from '../configs/uploadConfig.js';
import { useTheme } from '@mui/material/styles';
import { styles } from '../styles/data.jsx';
import { api } from '../../../api/index.js';

const UploadTab = () => {
    let { state } = useLocation();
    const navigate = useNavigate();
    const [values, setValues] = useState({});
    const [validations, setValidations] = useState({});
    const [edited, setEdited] = useState(false);
    const [cards, setCards] = useState([]);
    const [updating, setUpdating] = useState(false);

    const theme = useTheme();
    const style = styles(theme);

    const getDefaultValues = () => {
        const initValues = {file: null};
        return initValues
    }

    useEffect(() => {
        const fetchData = async () => {
            const cards = await getCardConfig(state.user);
            setCards(cards);
        };
        fetchData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (state.user){
            setValues(getDefaultValues())
        }
        // eslint-disable-next-line
    }, [cards]);

    const handleSaveClick = async() => {
        if (!updating){
            setUpdating(true);
            const results = await api("post", {data: values.file}, "data", state, navigate)
            if (results.status === 200){
                navigate(".", { replace: true, state: state });

            } else {
                navigate(".", { replace: true, state: state});
            }
            setUpdating(false);
        }
    }

    const handleCancelClick = () => {
        const tempValues = getDefaultValues();
        setValues({...tempValues});
        setEdited(false);
    }

    return (
        <Container sx={style.mainContainer}>
            {cards.length > 0 && 
                <DetailInfoCardPage
                    data={state.user}
                    cards={cards}
                    values={values}
                    setValues={setValues}
                    validations={validations}
                    setValidations={setValidations}
                    setEdited={setEdited}
                />
            }
            {cards.length > 0 && edited &&
                <Container sx={style.buttonContainer}>
                    <Button sx={style.buttonCancel} onClick={handleCancelClick}>Cancel</Button>
                    <Button sx={style.buttonSave} onClick={handleSaveClick} disabled={Object.values(validations).includes(false)} >
                        {updating ? "Saving" : "Save"}
                        {updating && <CircularProgress sx={style.buttonProgress}/>}
                    </Button>
                </Container>
            }
        </Container>
    );
};

export default UploadTab;