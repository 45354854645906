import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DetailsView from '../core/detailsView';
import { getDetailViewConfig } from './configs/dataConfigs';

const Data = () => {
    let { state } = useLocation();
    const [detailViewConfig, setDetailViewConfig] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const tempDetailViewConfig = await getDetailViewConfig(state);
            setDetailViewConfig(tempDetailViewConfig);
        };
        fetchData();
    }, [state]);

    return (
        <>
            {detailViewConfig && (
                <DetailsView
                    data={state}
                    titleConfig={detailViewConfig.titleConfig}
                    type={detailViewConfig.type}
                    tabs={detailViewConfig.tabs}
                    mainAction={detailViewConfig.mainAction}
                    actionItems={detailViewConfig.actionItems}
                    cards={detailViewConfig.cards}
                />
            )}
        </>
    );
};

export default Data;