import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DetailsView from '../core/detailsView';
import { getDetailViewConfig } from './configs/userInfoConfigs';
import { useTheme } from '@mui/material/styles';

const UserPage = () => {
    const theme = useTheme();
    let { state } = useLocation();
    const [detailViewConfig, setDetailViewConfig] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const tempDetailViewConfig = await getDetailViewConfig(state.record, theme);
            setDetailViewConfig(tempDetailViewConfig);
        };
        fetchData();
        // eslint-disable-next-line
    }, [state]);

    return (
        <>
            {detailViewConfig && (
                <DetailsView
                    titleConfig={detailViewConfig.titleConfig}
                    type={detailViewConfig.type}
                    tabs={detailViewConfig.tabs}
                    mainAction={detailViewConfig.mainAction}
                    actionItems={detailViewConfig.actionItems}
                    cards={detailViewConfig.cards}
                />
            )}
        </>
    );
};

export default UserPage;