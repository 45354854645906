export const styles = (theme) => {
    return {
        DataGridHeaderContainer: {
            alignItems: "center",
            display: 'flex',
            margin: '0px 0px 10px 0px',
            minWidth: '100%',
            justifyContent: 'space-between',
            paddingRight: '0px !important',
            maxWidth: '100% !important'
        },
        DataGridHeaderTitle: {
            margin: '0 5px 0 0',
        },
        DataGridHeaderButton: {
            marginLeft: '10px',
            borderRadius: '8px',
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "14px",
            letterSpacing: "0.5px",
            textAlign: "left",
            height: "35px",
            boxShadow: "none",
        },
        DataGridHeaderButtonActive: {
            backgroundColor: '#0082E1',
            color: '#FFFFFF',
            "&:hover": {
                backgroundColor: '#0082E1',
                color: '#FFFFFF',
            }
        },
        DataGridHeaderButtonInactive: {
            backgroundColor: '#D9D9D9',
            color: '#000000',
            "&:hover": {
                backgroundColor: '#D9D9D9',
                color: '#000000',
            }
        },
        bulkButton: {
            marginRight: '10px',
        }
    };
};
