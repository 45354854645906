import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Container, } from '@mui/material';
import { styles } from '../../styles/cards/card';
import Card from '../cards/card';

const DetailInfoCardPage = ({
        data,
        cards,
        values,
        setValues,
        validations,
        setValidations,
        setEdited,
        checkListValues,
    }) => {
    const theme = useTheme();
    const style = styles(theme);
    const [cardsLeft, setCardsLeft] = useState([]);
    const [cardsRight, setCardsRight] = useState([]);

    useEffect(() => {
            const tempCardsLeft = [];
            const tempCardsRight = [];
            cards.forEach((card) => {
                if (card.column === 1) {
                    tempCardsLeft.push(card);
                } else {
                    tempCardsRight.push(card);
                }
            });
            setCardsLeft([...tempCardsLeft]);
            setCardsRight([...tempCardsRight]);
        }
        // eslint-disable-next-line
    , [cards]);

    return (
        <Container sx={style.cardContainer}>
            <Container sx={style.cardContainerLeft}>
                {cardsLeft.map((card) => (
                    <Card 
                        key={card.title}
                        title={card.title}
                        fields={card.fields}
                        data={data}
                        values={values}
                        setValues={setValues}
                        validations={validations}
                        setValidations={setValidations}
                        bodyType={card.bodyType}
                        setEdited={setEdited}
                        checkListValues={checkListValues ? checkListValues[card.title] : {}}
                        columns={card.columns}
                        apiBody={card.apiBody}
                        apiType={card.apiType}
                        modalConfigs={card.modalConfigs}
                    />
                ))}
            </Container>
            <Container sx={style.cardContainerRight}>
                {cardsRight.map((card) => (
                    <Card 
                        key={card.title}
                        title={card.title}
                        fields={card.fields}
                        data={data}
                        values={values}
                        setValues={setValues}
                        validations={validations}
                        setValidations={setValidations}
                        bodyType={card.bodyType}
                        footer={false}
                        setEdited={setEdited}
                        checkListValues={checkListValues ? checkListValues[card.title] : {}}
                        columns={card.columns}
                        apiBody={card.apiBody}
                        apiType={card.apiType}
                        modalConfigs={card.modalConfigs}
                    />
                ))}
            </Container>
        </Container>
    );
};

export default DetailInfoCardPage;