import { getAdvisors } from "../../../utils/userConfig";
import { DeleteTwoTone } from '@mui/icons-material';


export const getDetailViewConfig = async(state, theme) => {
    const advisors = await getAdvisors(state.users);
    return {
        titleConfig: {
            breadcrumbs: true,
            field: 'name',
            mainAction: {
                type: 'text',
                text: 'Delete This Account',
                field: 'delete',
                textColor: theme.palette.error.main,
                textStyles: ["underline"],
                preIcon: <DeleteTwoTone />,
                modalConfig: {
                    delete: {
                        title: 'Delete Account',
                        type: 'text',
                        message: 'Are you sure you want to delete {data.name} account?',
                        submitLabel: 'Delete',
                        cancelLabel: 'Cancel',
                        submitColor: theme.palette.error.main,
                        cancelColor: theme.palette.primary.main,
                        submitType: 'delete',
                    }
                }
            },
        },
        type: 'accounts',
        cards: [
            {
                column: 1,
                title: "Account Information",
                bodyType: "form",
                fields: [
                    {
                        field: 'name',
                        headerName: 'Name',
                        cellType: 'text',
                        required: true,
                        type: 'text',
                        dataType: 'string',
                    },
                    {
                        field: 'startDate',
                        headerName: 'Start Date',
                        cellType: 'text',
                        required: true,
                        type: 'date',
                        dataType: 'string',
                    },
                    {
                        field: 'endDate',
                        headerName: 'End date',
                        cellType: 'text',
                        required: true,
                        type: 'date',
                        dataType: 'string',
                    },
                    {
                        field: 'advisorId',
                        headerName: 'Advisor',
                        required: true,
                        type: 'select',
                        dataType: 'string',
                        options: advisors,
                        multiple: true
                    }
                ]
            },
            {
                column: 2,
                title: "Tickers",
                bodyType: "table",
                columns: [
                    {
                        field: 'ticker',
                        title: "Ticker"
                    },
                    {
                        field: 'cusip',
                        title: "CUSIP"
                    },
                    {
                        field: 'startDate',
                        title: "Start Date"
                    },
                    {
                        field: 'endDate',
                        title: "End Date"
                    },
                    {
                        field: 'managed',
                        title: "Managed"
                    },
                ],
                modalConfigs: {
                    add: {
                        title: 'Add Ticker',
                        type: 'form',
                        submitLabel: 'Add',
                        cancelLabel: 'Cancel',
                        submitColor: theme.palette.error.main,
                        cancelColor: theme.palette.primary.main,
                        submitType: 'add',
                        form: {
                            fields: [
                                {
                                    field: 'ticker',
                                    headerName: 'Ticker',
                                    cellType: 'text',
                                    required: true,
                                    type: 'text',
                                    dataType: 'string',
                                },
                                {
                                    field: 'cusip',
                                    headerName: 'Cusip',
                                    cellType: 'text',
                                    required: true,
                                    type: 'text',
                                    dataType: 'string',
                                },
                                {
                                    field: 'startDate',
                                    headerName: 'Start Date',
                                    cellType: 'text',
                                    required: true,
                                    type: 'date',
                                    dataType: 'string',
                                },
                                {
                                    field: 'endDate',
                                    headerName: 'End date',
                                    cellType: 'text',
                                    required: true,
                                    type: 'date',
                                    dataType: 'string',
                                },
                                {
                                    field: 'managed',
                                    headerName: 'Managed',
                                    cellType: 'text',
                                    required: true,
                                    type: 'bool',
                                    dataType: 'string',
                                },
                            ]
                        }
                    },
                    edit: {
                        title: 'Edit Ticker',
                        type: 'form',
                        submitLabel: 'Edit',
                        cancelLabel: 'Cancel',
                        submitColor: theme.palette.error.main,
                        cancelColor: theme.palette.primary.main,
                        submitType: 'patch',
                        form: {
                            fields: [
                                {
                                    field: 'ticker',
                                    headerName: 'Ticker',
                                    cellType: 'text',
                                    required: true,
                                    type: 'text',
                                    dataType: 'string',
                                },
                                {
                                    field: 'cusip',
                                    headerName: 'Cusip',
                                    disabled: true,
                                    cellType: 'text',
                                    required: true,
                                    type: 'text',
                                    dataType: 'string',
                                },
                                {
                                    field: 'startDate',
                                    headerName: 'Start Date',
                                    cellType: 'text',
                                    required: true,
                                    type: 'date',
                                    dataType: 'string',
                                },
                                {
                                    field: 'endDate',
                                    headerName: 'End date',
                                    cellType: 'text',
                                    required: true,
                                    type: 'date',
                                    dataType: 'string',
                                },
                                {
                                    field: 'managed',
                                    headerName: 'Managed',
                                    cellType: 'text',
                                    required: true,
                                    type: 'bool',
                                    dataType: 'string',
                                },
                            ]
                        }
                    },
                    delete: {
                        field: 'cusip',
                        title: 'Delete Ticker',
                        type: 'text',
                        message: 'Are you sure you want to delete {thisValue.ticker} Ticker?',
                        submitLabel: 'Delete',
                        cancelLabel: 'Cancel',
                        submitColor: theme.palette.error.main,
                        cancelColor: theme.palette.primary.main,
                        submitType: 'delete',
                    }
                }
            }
        ]
    }
}