export const styles = (theme) => {
    return {
        detailViewContainer: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            padding: '0px !important',
            height: 'calc(100vh - 58px)',
        },
        detailViewLeftContainer: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "0px !important",
            margin: "0px",
        },
        detailViewLeftContainerOpen: {
            width: "calc(100% - 450px) !important",
        },
        detailViewButtonContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            padding: "10px",
            width: "calc(100% - 80px)",
        },
        detailViewButtonCancel: {
            backgroundColor: theme.palette.custom.gray1,
            color: theme.palette.error.main,
            marginRight: "10px",
            padding: "15px 20px",
            border: `1px solid ${theme.palette.custom.gray1}`,
            '&:hover': {
                backgroundColor: theme.palette.custom.gray2,
            }
        },
        detailViewButtonSave: {
            backgroundColor: theme.palette.primary.main,
            padding: "15px 20px",
            color: theme.palette.custom.white,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            }
        },
        detailViewButtonProgress: {
            color: theme.palette.custom.white,
            marginLeft: "10px",
            width: "20px !important",
            height: "20px !important",
        },
    };
};
