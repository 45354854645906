import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Container, Typography, Button, Select, MenuItem, TextField } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigatePrevIcon from '@mui/icons-material/NavigateBefore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { styles } from '../../styles/dataTableMD/dataTableFooter';

const DataTableFooter = (props) => {
    const theme = useTheme();
    const style = styles(theme);
    const {page, pageSize, pageSizes, setPage, setPageSize, totalPages} = props
    const [goToPageValue, setGoToPageValue] = useState("")

    const handleButtonClick = (button) => {
        if (button === "first"){
            setPage(0)
        }
        if (button === "prev"){
            setPage(page-1)
        }
        if (button === "next"){
            setPage(page+1)
        }
        if (button === "last"){
            setPage(totalPages-1)
        }
    }

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize)
    }

    const handlePageChange = (page) => {
        setPage(page)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter'){
            setPage(goToPageValue-1)
        }
    }

    const handleGoToChange = (value) => {
        if ((value <= 0 && value !== '') || value > totalPages){
           document.getElementById('goToPageTextBox').value = goToPageValue
        } else if (value === ''){
            setGoToPageValue('')
        } else {
            setGoToPageValue(value)
        }
    }

    return (
        <Container sx={style.dataTableFooterContainer}>
            <Container sx={style.dataTableFooterLeftContainer}>
            <Typography variant='body2'>
                {"Rows per page"}
            </Typography>
            <Select
                    value={pageSize}
                    onChange={e => handlePageSizeChange(e.target.value)}
                    sx={style.dataTablePageSizeSelect}
                >
                    {pageSizes.map((size, index) => (
                        <MenuItem
                        key={index}
                        value={size}
                        >
                        {size}
                        </MenuItem>
                    ))}
            </Select>
            {totalPages > 1 && (
                <Typography variant='body2'>
                    Go To
                </Typography>
            )}
            {totalPages > 1 && (
                <TextField
                    sx={style.dataTablePageSizeTextField}
                    id="goToPageTextBox"
                    variant="outlined"
                    type="number"
                    size='small'
                    value={goToPageValue}
                    onChange={e => handleGoToChange(e.target.value)}
                    onKeyDown={e => handleKeyDown(e)}
                />
            )}
            </Container>

            <Container sx={style.dataTableFooterRightContainer}>
                Page: {page+1} of {totalPages}
                {totalPages > 1 && (
                    <Button 
                        sx={style.dataTablePageButton}
                        size='small'
                        variant="contained"
                        onClick={e => handleButtonClick("first")}
                        disabled={page === 0}
                    >
                        <FirstPageIcon sx={style.dataTablePageButtonIcon}/>
                    </Button>
                )}
                {totalPages > 1 && (
                    <Button 
                        sx={style.dataTablePageButton}
                        size='small'
                        variant="contained"
                        onClick={e => handleButtonClick("prev")}
                        disabled={page === 0}
                    >
                        <NavigatePrevIcon sx={style.dataTablePageButtonIcon}/>
                    </Button>
                )}
                {totalPages >= page+2 && (
                    <Button 
                        sx={style.dataTablePageButton}
                        size='small'
                        variant="contained"
                        onClick={e => handlePageChange(page+1)}
                    >
                        <Typography sx={style.dataTablePageButtonText} variant='body1'>{page+2}</Typography>
                    </Button>
                )}
                {totalPages >= page+3 && (
                    <Button 
                        sx={style.dataTablePageButton}
                        size='small'
                        variant="contained"
                        onClick={e => handlePageChange(page+2)}
                    >
                        <Typography sx={style.dataTablePageButtonText} variant='body1'>{page+3}</Typography>
                    </Button>
                )}
                {totalPages >= page+4 && (
                    <Button 
                        sx={style.dataTablePageButton}
                        size='small'
                        variant="contained"
                        onClick={e => handlePageChange(page+3)}
                    >
                        <Typography sx={style.dataTablePageButtonText} variant='body1'>{page+4}</Typography>
                    </Button>
                )}
                {totalPages >= page+5 && (
                    <Button 
                        sx={style.dataTablePageButton}
                        size='small'
                        variant="contained"
                        onClick={e => handlePageChange(page+4)}
                    >
                        <Typography sx={style.dataTablePageButtonText} variant='body1'>{page+5}</Typography>
                    </Button>
                )}
                {totalPages >= page+6 && (
                    <Button 
                        sx={style.dataTablePageButton}
                        size='small'
                        variant="contained"
                        onClick={e => handlePageChange(page+5)}
                    >
                        <Typography sx={style.dataTablePageButtonText} variant='body1'>{page+6}</Typography>
                    </Button>
                )}
                {totalPages > 5 && (
                    <Button 
                        sx={style.dataTablePageMoreHoriz}
                        size='small'
                        variant="contained"
                        disabled
                    >
                        <MoreHorizIcon sx={style.dataTablePageButtonIcon}/>
                    </Button>
                )}
                {totalPages > 5 && (
                    <Button 
                        sx={style.dataTablePageButton}
                        size='small'
                        variant="contained"
                        onClick={e => handleButtonClick("prev")}
                    >
                        <Typography sx={style.dataTablePageButtonText} variant='body1'>{totalPages}</Typography>
                    </Button>
                )}
                {totalPages > 1 && (
                    <Button 
                        sx={style.dataTablePageButton}
                        size='small'
                        variant="contained"
                        onClick={e => handleButtonClick("next")}
                        disabled={page === totalPages-1 }
                    >
                        <NavigateNextIcon sx={style.dataTablePageButtonIcon}/>
                    </Button>
                )}
                {totalPages > 1 && (
                    <Button 
                        sx={style.dataTablePageButton}
                        size='small'
                        variant="contained"
                        onClick={e => handleButtonClick("last")}
                        disabled={page === totalPages-1 }
                    >
                        <LastPageIcon sx={style.dataTablePageButtonIcon}/>
                    </Button>
                )}
            </Container>
        </Container>
    );

};

export default DataTableFooter;