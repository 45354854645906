import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Container, Typography, TextField, FormControl } from '@mui/material';
import { styles } from '../../styles/dataTableMD/dataTableFilters';

import { FilterAltTwoTone } from '@mui/icons-material';

const DataTableSearch = ({searchFields, processFilter}) => {
    const theme = useTheme();
    const style = styles(theme);
    const [value, setValue] = useState("")

    const handleValueChange = (thisValue) => {
        setValue(thisValue)
        processFilter(searchFields, thisValue)
    }

    return (
        <Container sx={style.DataGridFiltersContainer}>
            <FilterAltTwoTone sx={style.DataGridFilterIcon} />
            <Typography variant='subtitle1'>
                Filters:
            </Typography>
            <FormControl sx={style.DataGridFilterFormControl}>
                <TextField
                    sx={style.textBox}
                    value={value}
                    onChange={(e) => handleValueChange(e.target.value)}
                />
            </FormControl>
        </Container>
    );

};

export default DataTableSearch;