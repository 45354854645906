import { DeleteTwoTone } from '@mui/icons-material';


export const getDetailViewConfig = async(state, theme) => {
    return {
        titleConfig: {
            breadcrumbs: true,
            field: 'name',
            mainAction: {
                type: 'text',
                text: 'Delete This Dividend',
                field: 'delete',
                textColor: theme.palette.error.main,
                textStyles: ["underline"],
                preIcon: <DeleteTwoTone />,
                modalConfig: {
                    delete: {
                        title: 'Delete Dividend',
                        type: 'text',
                        message: 'Are you sure you want to delete dividend with ticker {data.id}?',
                        submitLabel: 'Delete',
                        cancelLabel: 'Cancel',
                        submitColor: theme.palette.error.main,
                        cancelColor: theme.palette.primary.main,
                        submitType: 'delete',
                    }
                }
            },
        },
        type: 'dividends',
        cards: [
            {
                column: 1,
                title: "Dividend Information",
                bodyType: "form",
                fields: [
                    {
                        field: 'id',
                        headerName: 'Ticker',
                        cellType: 'text',
                        required: true,
                        type: 'text',
                        dataType: 'string',
                    },
                    {
                        field: 'amount',
                        headerName: 'Amount',
                        cellType: 'text',
                        required: true,
                        type: 'text',
                        dataType: 'string',
                    },
                    {
                        field: 'exDate',
                        headerName: 'Ex Date',
                        cellType: 'text',
                        required: true,
                        type: 'date',
                        dataType: 'string',
                    }
                ]
            }
        ]
    }
}