import React, { useState } from 'react';
import { Container, Typography, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { styles } from '../../styles/detailsView/detailViewTabs';

const DetailViewTabs = ({data, tabs, handleOpen, updating, setUpdating, handleModalOpen}) => {
    const theme = useTheme();
    const style = styles(theme);
    const [currentTab, setCurrentTab] = useState(tabs[0]);
    

    const handleChange = (tab) => {
        setCurrentTab(tab);
    }

    const handleCurrentTab = () => {
        return (
            <currentTab.component data={data} handleOpen={handleOpen} updating={updating} setUpdating={setUpdating} handleModalOpen={handleModalOpen}/>
        )
    }

    return (
        <Container sx={style.detailViewTabsContainer}>
            <Container sx={style.detailViewTabsListContainer}>
            {tabs.map((tab) => (
                <Container sx={[style.detailViewTabsListTabContainer, currentTab.title === tab.title && style.detailViewTabActive]} key={tab.title} >
                    <Typography 
                        key={tab.title} 
                        onClick={() => handleChange(tab)}
                        sx={[style.detailViewTabsListButton, currentTab.title === tab.title && style.detailViewTabTextActive]}
                    >
                        {tab.title}
                    </Typography>
                    {tab.chip && <Chip label={6} sx={[style.detailViewTabsTitleChip, currentTab.title === tab.title && style.detailViewTabsTitleChipActive]}/>}
                </Container>
            ))}
            </Container>
            <Container sx={style.detailViewTabsContentContainer}>
                {handleCurrentTab()}
            </Container>

        </Container>
    );
};

export default DetailViewTabs;