export const styles = (theme) => {
    return {
        detailViewContainerModal: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '30%',
            height: 'auto',
            backgroundColor: 'white',
            padding: '0px !important',
            margin: '0px',
            position: 'absolute',
            top: '20%',
            left: '35%',
            outline: 'none',
            borderRadius: '5px',
            overflow: 'hidden',
        },
        detailViewContainerModalHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '15px',
            borderBottom: `1px solid ${theme.palette.custom.borderColor}`,
            backgroundColor: theme.palette.primary.main,
        },
        detailViewContainerModalHeaderTitle: {
            fontSize: '20px',
            fontWeight: '700',
            color: theme.palette.custom.white,
        },
        detailViewContainerModalHeaderClose: {
            fontSize: '20px',
            cursor: 'pointer',
            color: theme.palette.custom.white,
        },
        detailViewContainerModalBody: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'auto',
            flexGrow: '2',
            width: '100%',
            padding: '10px',
            paddingTop: '20px',
            paddingBottom: '20px',
            color: theme.palette.secondary.main,
        },
        detailViewContainerModalBodyLabel: {
            fontSize: '16px',
            fontWeight: '400',
            color: theme.palette.secondary.main,
        },
        detailViewContainerModalBodyTextArea: {
            width: '100%',
            height: '300px',
            marginTop: '10px',
            marginBottom: '10px',
            padding: '0px !important',
            borderRadius: '5px',
            border: '1px solid #F0F0F0',
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.custom.main,
            overflow: 'scroll',
            maxHeight: '300px !important',
            '& .MuiOutlinedInput-root': {
                padding: '0px !important',
                backgroundColor: theme.palette.custom.main,
            },
            '& .MuiOutlinedInput-input': {
                backgroundColor: theme.palette.secondary.main,
                border: 'none !important',
                fontSize: '18px !important',
                fontWeight: '600 !important',
                fontFamily: 'Nunito Sans',
                paddingLeft: '20px !important',
                overflow: 'scroll',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
            },
            '&.Mui-focused .MuiOutlinedInput-input': {
                padding: '0px !important',
            },
        },
        detailViewContainerModalFooter: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
            borderTop: '1px solid #F0F0F0',
            margin: '0px',
            height: '90px',
        },
        detailViewContainerModalFooterCancelButton: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0px',
            marginRight: '10px',
            paddingTop: '10px',
            paddingBottom: '10px',
            borderRadius: '4px',
            width: 'auto',
            fontSize: '16px',
            fontWeight: '400',
            backgroundColor: theme.palette.custom.gray1,
            color: theme.palette.error.main,
            '&:hover': {
              backgroundColor: theme.palette.custom.gray2,
            }
        },
        detailViewContainerModalFooterSubmitButton: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0px',
            paddingTop: '10px',
            paddingBottom: '10px',
            borderRadius: '4px',
            width: 'auto',
            fontSize: '16px',
            fontWeight: '400',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.custom.white,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            }
        },
        detailViewContainerModalFooterSubmitProgress: {
            width: '15px !important',
            height: '15px !important',
            color: '#FFFFFF',
            marginLeft: '10px',
        },
    };
};
