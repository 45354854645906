import { getPermissionOptions } from "../../../utils/userConfig";
/**
 * This is the detault columns for Users
 * field - This is the property on the object that wil be displayed within this column
 * headerName - This is the header that will be displayed for the column
 * hideableable: This is a custom field that will determine whether a column will be able to be added to the grid or not (visibility) 
 */
export const getUsersColumns = async (theme) => {
    return [
        {
            field: 'firstName',
            headerName: 'First Name',
            cellType: 'text',
            showColumn: true,
            showAdd: true,
            required: true,
            type: 'text',
            dataType: 'string',
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            cellType: 'text',
            showColumn: true,
            showAdd: true,
            required: true,
            type: 'text',
            dataType: 'string',
        },
        {
            field: 'email',
            headerName: 'Email',
            cellType: 'text',
            showColumn: true,
            showAdd: true,
            required: true,
            type: 'text',
            dataType: 'email',
        },
        {
            field: 'role',
            headerName: 'Role',
            cellType: 'chip',
            typeConfig: {
                colors: {
                    "Admin": {background: theme.palette.custom.blue, color: theme.palette.custom.white},
                    "Advisor": {background: theme.palette.custom.green, color: theme.palette.custom.white},
                },
            },
            displayConfig: {
                "admin": "Admin",
                "advisor": "Advisor",
            },
            showColumn: true,
            showAdd: true,
            required: true,
            type: 'select',
            dataType: 'string',
            options: await getPermissionOptions(),
        }
    ];
}