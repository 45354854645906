import UploadTab from "../components/uploadTab"


export const getDetailViewConfig = async(data) => {
    return {
        titleConfig: {
            breadcrumbs: false,
            breadcrumbsReplacment: 'Data',
            title: 'Data Management',
        },
        type: 'data',
        tabs: [
            {title: 'Upload', component:UploadTab}
        ],

    }
}