import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Container, Typography, Select, MenuItem, OutlinedInput, InputLabel, FormControl } from '@mui/material';
import { styles } from '../../styles/dataTableMD/dataTableFilters';

import { FilterAltTwoTone } from '@mui/icons-material';

const DataGridFilters = ({filters, processFilter}) => {
    const theme = useTheme();
    const style = styles(theme);
    const tempValues = {}
    for (let filter in filters){
        tempValues[filters[filter].field] = []
    }
    const [selectValues, setSelectValues] = useState(tempValues)

    const handleFilterChange = (field, value) => {

        selectValues[field] = value
        setSelectValues({...selectValues})
        processFilter(field, value)
    }

    return (
        <Container sx={style.DataGridFiltersContainer}>
            <FilterAltTwoTone sx={style.DataGridFilterIcon} />
            <Typography variant='subtitle1'>
                Filters:
            </Typography>

            {filters.map((filter, index) => (
                <FormControl sx={style.DataGridFilterFormControl} key={index}>
                    <InputLabel id="demo-multiple-name-label" sx={style.DataGridFilterInputLabel}>{filter.title}</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectValues[filter.field]}
                        onChange={e => handleFilterChange(filter.field, e.target.value)}
                        input={<OutlinedInput label={filter.title} />}
                        sx={style.DataGridFiltersSelect}
                    >
                        {filter.options.map((option, opIndex) => (
                            <MenuItem
                            key={opIndex}
                            value={option.value}
                            >
                            {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ))}
        </Container>
    );

};

export default DataGridFilters;