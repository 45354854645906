export const styles = (theme) => {
    return {
        mainContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        buttonContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            padding: "10px",
            marginBottom: "60px",
            width: "calc(100% - 80px)",
        },
        buttonCancel: {
            marginRight: "10px",
            padding: "15px 20px",
            backgroundColor: theme.palette.custom.gray1,
            color: theme.palette.error.main,
            '&:hover': {
              backgroundColor: theme.palette.custom.gray2,
            }
        },
        buttonSave: {
            padding: "15px 20px",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.custom.white,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            }
        },
        buttonProgress: {
            color: theme.palette.custom.white,
            marginLeft: "10px",
            width: "20px !important",
            height: "20px !important",
        },
    }
}