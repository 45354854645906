export const getCardConfig = async(state) => {
    const accountOptions = [];
    const tickerOptions = [];
    for (let account in state.accounts) {
        accountOptions.push({
            value: state.accounts[account].id,
            label: state.accounts[account].name
        });
        for (let ticker in state.accounts[account].tickers) {
            tickerOptions.push({
                value: state.accounts[account].tickers[ticker].cusip,
                label: state.accounts[account].tickers[ticker].ticker,
                account: state.accounts[account].id,
            });
        }
        tickerOptions.push({
            value: "All Tickers",
            label: "1. All Tickers",
            account: state.accounts[account].id,
        });
    }
    accountOptions.sort((a, b) => {return a["label"] > b["label"] ? 1 : -1;});
    return {
        title: "Export Position File",
        bodyType: "form",
        fields: [
            {
                field: 'date',
                headerName: 'Position Date',
                cellType: 'text',
                required: true,
                type: 'date',
                dataType: 'string',
            }
        ]
    }
}