export const styles = (theme) => {
    return {
        dataTableContainer: {
            minHeight: "calc(100vh - 353px)",
            maxHeight: "calc(100vh - 353px)",
            margin: '0px',
            backgroundColor: theme.palette.custom.white,
            border: `1px solid ${theme.palette.custom.gray1}`,
            width: "100%",
            borderRadius: "6px",
        },
        dataTableHeaderRow: {
            height: "56px",
            backgroundColor: theme.palette.primary.main,
            borderBottom: `1px solid ${theme.palette.custom.black}`,
        },
        dataTableHeaderCell: {
            padding: "0px",
            paddingTop: "15px",
            paddingBottom: "15px",
            borderBottom: `2px solid ${theme.palette.custom.gray5}`,
            color: theme.palette.custom.white,
        },
        dataTableHeaderCellText: {
            borderLeft: `1px solid ${theme.palette.custom.gray1}`,
            fontWeight: "bold",
            textAlign: "left",
            margin: "0px",
            padding: "0px",
            paddingLeft: "16px",
            paddingTop: "5px",
            paddingBottom: "5px",
        },
        arrowDropIcon: {
            float: "right"
        },
        dataTableHeaderCellTextFirst: {
            borderLeft: 'none',
        },
        dataTableCellTextBold: {
            fontWeight: "bold",
        },
        dataTableCell: {
            borderBottom: `1px solid ${theme.palette.custom.gray1}`,
        },
        dataTableChip: {
            borderRadius: '4px',
            margin: '0 0 10px 0',
        },
        dataTableProfileBubble: {
            borderRadius: '50%',
            width: '45px',
            height: '45px',
            margin: '0px 0',
        },
        dataTableAlertIcon: {
            float: 'right',
            marginRight: '10px',
        },
        dataTableCellGroup: {
        },
        getChipColor: (color) => {
            return {
                backgroundColor: color.background,
                color: color.color,
            };
        }
    };
};
