export const styles = (theme) => ({
    DataGridStyleContainer: {
        fontFamily: "Nunito Sans",
        margin: '0px',
        marginLeft: '20px',
        marginTop: '20px',
        width: "calc(100% - 40px)",
        '& h4': {
            display: 'flex',
            '& .MuiToggleButtonGroup-root': {
                margin: '0 0 0 20px'
            }
        },
    },
    dataTableLoadingContainer: {
        display: "flex",
        justifyContent: "center",
        minHeight: "calc(100vh - 353px)",
        maxHeight: "calc(100vh - 353px)",
        minWidth: '100%',
        borderRadius: "0px",
        margin: '0px',
        backgroundColor: '#FFF',
        border: '1px solid #F0F0F0',
        alignItems: "center",
    },
});