import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Container, Menu, MenuItem,Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { rgbToHex, useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { api } from '../../api/index';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { styles } from '../styles/topbarStyles';

export const TopBarComponent = () => {
    let { state } = useLocation();
    const theme = useTheme();
    const style = styles(theme);
    const [loading, setLoading] = useState(false);

    const settings = [{label: 'Profile', path: '/profile'}, {label: 'Logout', path: '/auth/logout'}];
    const [dropMenu, setDropMenu] = useState(false);

    const navigate = useNavigate();

    const handleAccountClick = async () => {
        setDropMenu(true);
    }

    const handleCloseUserMenu = (path) => {
        setDropMenu(false);
        navigate(path, {replace: true, state: state});
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = async () => {
        setAnchorEl(null);
    };

    const handleExport = async () => {
        setLoading(true);
        const results = await api("get", {}, "Accounts", state, navigate);
        //const results = await api("post", values, "masterexport", state, navigate);
        if (results.status === 200) {
            if(results.data.length > 0){
              const flattenData = convertData(results.data)
                const csv = convertToCSV(flattenData)
                const date = new Date(Date.now())
                downloadCSV(csv, `Master Account File - ${date.toLocaleDateString()}`)
                setLoading(false);
            }
            else {
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
        setLoading(false);
    }

    const convertData = (data) => {
        let result = [];
        data.forEach(item => {
          for (const key in item.tickers) {
            const ticker = item.tickers[key]
            let tickerData = {}
            tickerData["id"] = item.id
            tickerData["name"] = item.name
            tickerData["advisorName"] = item.advisorName
            tickerData["startDate"] = item.startDate
            tickerData["ticker"] = ticker.ticker
            tickerData["cusip"] = ticker.cusip
            tickerData["managed"] = (ticker.managed === true || ticker.managed === 'true') ?? 'Yes' | 'No'
            result.push(tickerData)
          }
        });
        return result;
      };
  
      const convertToCSV = (data) => {
        const array = [Object.keys(data[0])].concat(data);
      
        return array.map(row => {
          return Object.values(row).map(value => {
            return typeof value === 'string' ? JSON.stringify(value) : value;
          }).join(',');
        }).join('\n');
      };
    
        const downloadCSV = (csv, filename) => {
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          const link = document.createElement('a');
          if (link.download !== undefined) { // feature detection
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        };

        const LoadingOverlay = () => {
            return (
              <div style={{position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                background: 'rgb(0,0,0,0.3)',
                display: 'flex',
                justifyContent : 'center',
                alignItems : 'center',
                zIndex : 9999
                }}>
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                <p>Exporting File...</p>
              </div>
            );
          };

    return (
        <AppBar sx={style.appTopBar} position='static' color='transparent'>
            <Container sx={style.rightTopBarContainer}>
                {loading && <LoadingOverlay />}
                {state.user?.role === 'admin'
                    &&<Container sx={style.notificationContainer} onClick={() => navigate('/dividends', {state: state})}>
                        Dividend
                    </Container>
                }
                {state.user?.role === 'admin'
                    &&<Container sx={style.notificationContainer} onClick={() => navigate('/users', {state: state})}>
                        Users
                    </Container>
                }
                {state.user?.role === 'admin'
                    &&<Container sx={style.notificationContainer} onClick={() => navigate('/accounts', {state: state})}>
                        Accounts
                    </Container>
                }
                <Container sx={style.notificationContainer} onClick={() => navigate('/reports', {state: state})}>
                        Reports
                </Container>
                {state.user?.role === 'admin'
                    &&
                    <div style={{marginRight : 30}}>
                        <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            Exports
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={() => {handleClose()}}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => {handleClose(); handleExport()}}>Master Account File</MenuItem>
                            <MenuItem onClick={() => {handleClose(); navigate('/positionFile', {state: state})}}>Master Position File</MenuItem>
                        </Menu>
                        </div>
                }
                <Container sx={style.accountcontainer}>
                    <AccountCircleIcon sx={style.accountCircle}/>
                    <Typography onClick={() => handleAccountClick()} variant="h6" id="userNameLabel" sx={style.userName}>{state.user?.firstName ? `${state.user.firstName} ${state.user.lastName}` : "Unkown User"}</Typography>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={document.getElementById('userNameLabel')}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={dropMenu}
                        onClose={() => handleCloseUserMenu()}
                        >
                        {settings.map((setting) => (
                            <MenuItem key={setting.label} onClick={() => handleCloseUserMenu(setting.path)}>
                                <Typography textAlign="center">{setting.label}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Container>
            </Container>
        </AppBar>
    );
    
}