export const cardStyles = (theme) => {
    return {
        mdCard: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.custom.white,
            margin: '20px',
            borderRadius: '5px',
            border: `1px solid ${theme.palette.custom.gray4}`,
            overflow: 'hidden',
        },
        mdCardHeader: {
            padding: '15px !important',
            borderBottom: `1px solid ${theme.palette.secondary.gray4}`,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.custom.white,
        },
        mdCardHeaderTitle: {
            marginLeft: '15px',
            fontSize: '18px',
            fontWeight: '400',
        },
        mdCardBody: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            width: 'auto',
            margin: '30px',
            padding: '20px !important',
            border: `1px solid ${theme.palette.custom.gray1}`,
            borderRadius: '4px',
        },
    }
}