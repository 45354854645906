import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DataTableMD from '../core/dataTableMD';
import { getAccountsColumns } from './configs/accountsDataTableConfig';

const UsersPage = (props) => {
    const theme = useTheme();
    let { state } = useLocation()
    const [columns, setColumns] = useState([])  
    useEffect(() => {
        const getFilters = async () => {
            const columns = await getAccountsColumns(state, theme)
            setColumns(columns)
        }
        getFilters()
        // eslint-disable-next-line
    }, [])
    const addSuccessMessage = "The new user has been successfully added to the system. "
    return (
        <DataTableMD
            title="Accounts"
            filters = {{}}
            search={{fields: ["name", "id", "advisorName"]}}
            columns = {columns}
            addButton={true}
            addSuccessMessage={addSuccessMessage}
            sort={{field: 'firstName', order: 'asc'}}
        />
    );
};

export default UsersPage;