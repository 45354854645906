export const getDividendColumns = async (state, theme) => {
    return [
        {
            field: 'id',
            headerName: 'Ticker',
            cellType: 'text',
            showColumn: true,
            showAdd: true,
            required: true,
            type: 'text',
            dataType: 'string',
        },
        {
            field: 'amount',
            headerName: 'Amount',
            cellType: 'text',
            showColumn: true,
            showAdd: true,
            required: true,
            type: 'text',
            dataType: 'string',
        },
        {
            field: 'exDate',
            headerName: 'exDate',
            cellType: 'text',
            showColumn: true,
            showAdd: true,
            required: true,
            type: 'date',
            dataType: 'string',
        },
    ];
}

export const getModalConfig = async (theme) => {
    return {
        bulk: {
            title: 'Add Bulk Dividends',
            type: 'form',
            submitLabel: 'Add',
            cancelLabel: 'Cancel',
            submitColor: theme.palette.error.main,
            cancelColor: theme.palette.primary.main,
            submitType: 'add',
            form: {
                fields: [
                    {
                        field: 'file',
                        headerName: 'Bulk CSV File',
                        cellType: 'text',
                        required: true,
                        type: 'file',
                        dataType: 'string',
                    }
                ]
            }
        }
    }
}