import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography, Chip } from '@mui/material';

import { styles } from '../../styles/dataTableMD/dataTable';
import { ArrowDropDownTwoTone, ArrowDropUpTwoTone } from '@mui/icons-material';

const processAgoTime = (date) => {
    const TotalMiliSecs = Date.now() - new Date(date).getTime()
    const TotalSeconds = TotalMiliSecs / 1000
    if (TotalSeconds < 60) {
        return 'Just Now'
    }
    const TotalMinutes = TotalSeconds / 60
    if (TotalMinutes < 60) {
        return `${Math.floor(TotalMinutes)} minutes ago`
    }
    const TotalHours = TotalMinutes / 60
    if (TotalHours < 24) {
        return `${Math.floor(TotalHours)} hours ago`
    }
    const TotalDays = TotalHours / 24
    return `${Math.floor(TotalDays)} days ago`
}

const getAlertIcon = (alertIcon) => {
    return ""
}

function getInitials(username) {
    if (typeof username !== 'string' || username.trim() === '') return 'N';

    const nameParts = username.split(' ');

    if (nameParts.length === 1) {
        return nameParts[0].charAt(0).toUpperCase();
    } else {
        return nameParts.map(part => part.charAt(0).toUpperCase()).join('');
    }
}



const DataTable = ({ data, fields, handleRowClick, sortData, currentOrder, sortField}) => {
    const theme = useTheme();
    const style = styles(theme);
    const [processedData, setProcessedData] = useState([])

    useEffect(() => {
        const tempProcessedData = []



        for (let row in data) {
            const tempItem = {}
            for (let field in fields) {
                if (fields[field].showColumn) {
                    const preString = fields[field].preString ? fields[field].preString : ""
                    const postString = fields[field].postString ? fields[field].postString : ""
                    let cellData = data[row][fields[field].field]
                    if (fields[field].fieldConfig && fields[field].fieldConfig === "combined") {
                        cellData = `${data[row][fields[field].field]} ${data[row][fields[field].secondField]}`
                    }
                    if (fields[field].displayConfig) {
                        cellData = fields[field].displayConfig[cellData]
                    }
                    if (cellData === undefined) {
                        cellData = "N/A"
                    }
                    let BeforeAlertIconsList = []
                    let AfterAlertIconsList = []
                    const alertIcons = fields[field].alertIcons
                    if (alertIcons) {
                        for (let alertIcon in alertIcons) {
                            if (data[row][alertIcons[alertIcon].field] === alertIcons[alertIcon].value) {
                                if (alertIcons[alertIcon].placement === "pre") {
                                    BeforeAlertIconsList.push(getAlertIcon(alertIcons[alertIcon].icon))
                                } else if (alertIcons[alertIcon].placement === "post") {
                                    AfterAlertIconsList.push(getAlertIcon(alertIcons[alertIcon].icon))
                                }
                            }
                        }
                    }
                    const fieldValue = <Typography sx={[style.dataTableCellGroup, fields[field].textConfig?.bold && style.dataTableCellTextBold]}>
                        {BeforeAlertIconsList}
                        {`${preString}${cellData}${postString}`}
                        {AfterAlertIconsList}
                    </Typography>
                    let fieldValueHtml = null
                    if (fields[field].cellType === 'chip') {
                        fieldValueHtml = <Chip
                            sx={[style.getChipColor(fields[field].typeConfig.colors[cellData]), style.dataTableChip]}
                            label={fieldValue} />
                    } else if (fields[field].cellType === 'hoursAgoDate') {
                        fieldValueHtml = <Typography variant='body2'>
                            {processAgoTime(cellData)}
                            <br></br>
                            {new Date(cellData).toLocaleString()}
                        </Typography>
                    } else if (fields[field].cellType === 'userName') {
                        const newInitials = getInitials(cellData)
                        fieldValueHtml =
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Chip
                                    sx={[style.getChipColor("lightblue"), style.dataTableProfileBubble]}
                                    label={<span style={{ color: '#05adf5' }}>{newInitials}</span>}
                                    style={{ marginRight: '10px' }}
                                />
                                <Typography component={'div'} variant='body1'>
                                    {fieldValue} {}
                                </Typography>
                            </div>
                    }
                    else if (fields[field].cellType === 'Availability') {
                        let leaveEndDate = data[row].leaveEndDate
                        if (leaveEndDate) {
                            const leaveDate = 'Until ' + new Date(leaveEndDate).toLocaleDateString('en-US')
                            fieldValueHtml =
                                <div>
                                    <Chip
                                        sx={[style.getChipColor(fields[field].typeConfig.colors[cellData]), style.dataTableChip]}
                                        label={fieldValue}
                                    />
                                    <Typography component={'div'} variant='body2'>
                                        {leaveDate}
                                    </Typography>
                                </div>

                        }
                        else if (!leaveEndDate) {
                            fieldValueHtml =
                                <Chip
                                    sx={[style.getChipColor(fields[field].typeConfig.colors[cellData]), style.dataTableChip]}
                                    label={fieldValue}
                                />
                        }
                    }
                    else {
                        fieldValueHtml = fieldValue
                    }
                    tempItem[fields[field].field] = fieldValueHtml
                }
            }
            tempProcessedData.push(tempItem)
        }
        setProcessedData(tempProcessedData)
        // eslint-disable-next-line
    }, [data, fields])

    return (
        <TableContainer sx={style.dataTableContainer}>
            <Table>
                <TableHead>
                    <TableRow sx={style.dataTableHeaderRow}>
                        {fields.map((field, index) => {
                            if (field.showColumn){
                                return (
                                    <TableCell key={index} sx={style.dataTableHeaderCell} onClick={() => sortData(null, field.field, null)}>
                                        <Typography
                                            variant='body2'
                                            sx={[style.dataTableHeaderCellText, index === 0 && style.dataTableHeaderCellTextFirst]}
                                        >
                                            {field.headerName}
                                            {sortField === field.field && currentOrder === "asc" && (
                                                <ArrowDropUpTwoTone sx={style.arrowDropIcon} />
                                            )}
                                            {sortField === field.field && currentOrder === "desc" && (
                                                <ArrowDropDownTwoTone sx={style.arrowDropIcon} />
                                            )}
                                        </Typography>
                                    </TableCell>
                                )
                            } else {
                                return null
                            }
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {processedData.map((row, index) => (
                        <TableRow key={index} onClick={() => handleRowClick(data[index].id)}>
                            {fields.map((field, fIndex) => {
                                if (field.showColumn) {
                                    return (
                                        <TableCell key={fIndex} sx={style.dataTableCell}>
                                            {row[field.field]}
                                        </TableCell>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </TableContainer>
    );

};

export default DataTable;