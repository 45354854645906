import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'

import { styles } from './styles/styles';
import Card from '../core/components/cards/card';
import { getCardConfig } from './configs/reportsConfigs';
import { api } from '../../api/index';
import { positionColumnNames, transactionColumnNames, PLColumnNames } from '../../utils/dataUtils';

const ReportsPage = () => {
    const navigate = useNavigate()
    let { state } = useLocation();
    const theme = useTheme();
    const style = styles(theme);
    const defaultValues = {id: "", dateStart: "", dateEnd: "", report: "", cusip: ""};
    const defaultValidations = {id: false, dateStart: false, dateEnd: false, report: false};

    const [values, setValues] = useState(defaultValues);
    const [validations, setValidations] = useState(defaultValidations);
    const [edited, setEdited] = useState(false);
    const [config, setConfig] = useState({});
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [error, setError] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const config = await getCardConfig(state);
            setConfig(config);
        }
        fetchData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
      const disabled = Object.values(validations).includes(false);
      setDisabled(disabled);
      // eslint-disable-next-line
    }, [validations]);


    const handleGenerate = async () => {
      setLoading(true);
      // setDisabled(true);
      setError(false)
      const results = await api("post", values, "generateReport", state, navigate);
      if (results.status === 200) {
        // setValues(defaultValues);
        // setValidations(defaultValidations);
        // setEdited(false);
        const doc = new jsPDF({orientation: 'l'});
        doc.setFontSize(8);
        doc.setFillColor(255,255,255)
        doc.rect(10, 10, 150, 160, "F");
        doc.addImage("/exceedLogo.jpg", "JPG", 5, 5, 41.6, 18.6);
        doc.text(values.report, 250, 9);
        doc.text(`Account: ${values.id}`, 250, 13);
        doc.text(`Start Date: ${ results.data[1].startDate ? results.data[1].startDate.substring(0,10) : values.dateStart}`, 250, 17);
        doc.text(`End Date: ${results.data[1].endDate ? results.data[1].endDate.substring(0,10) : values.dateEnd}`, 250, 21);
        let tableStart = 30
        let tableFontSize = 5.502
        if (values.report === "P&L") {
          tableFontSize = 7
          tableStart = 100
          doc.setFontSize(14);
          doc.setFont(undefined, 'bold');
          doc.text(`Portfolio Summary`, 5, 35);
          doc.setFont(undefined, 'normal');
          doc.setFontSize(12);
          const tableData = [
            ["Market Value", results.data[1].marketValue],
            ["Stock Return (%)", results.data[1].stockReturn],
            ["Option Yield (%)", results.data[1].optionYield],
            ["Income from Options", results.data[1].optionIncome],
            ["Total Performance (%)", results.data[1].totalPerformance],
            ["Portfolio Volatility (%)", results.data[1].stockVolatility],
            ["Portfolio Volatility with Options (%)", results.data[1].positionVolatility]
          ]
          doc.autoTable({
            startY: 40,
            body: tableData,
            tableWidth: 90,
            columnStyles: [
              {halign: "left" },
              {halign: "right" },
            ]
          });
        }
        let columns = positionColumnNames;
        let pdfData = results.data
        if (values.report === "Option Transactions") {
          columns = transactionColumnNames;
          tableFontSize = 4.5
        }
        if (values.report === "P&L") {
          columns = PLColumnNames;
          const sortedData = results.data[0].sort((a, b) => {
              return a["Ticker"] > b["Ticker"] ? 1 : -1;
          });
          pdfData = sortedData

        } else {
          const sortedData = results.data.sort((a, b) => {
            return a["Business Date"] > b["Business Date"] ? 1 : -1;
          });
          pdfData = sortedData
        }
        const newPdfData = []
        for (let data in pdfData) {
          const tempList = []
          for (let item in pdfData[data]) {
            if (pdfData[data][item] === null || pdfData[data][item] === undefined) {
              pdfData[data][item] = " ";
            }
            if (item !== "cusip" && item !== "MarketValue"){
              tempList.push(`${pdfData[data][item]}`)
            }
          }
          newPdfData.push(tempList)
        }
        doc.setFontSize(6);
        if (values.report === "P&L") {
          doc.autoTable({
            startY: tableStart,
            head: [columns],
            body: newPdfData,
            headStyles :{fillColor : [200, 200, 200], textColor: [0,0,0]},
            styles: {
              lineColor: [0, 0, 0],
              lineWidth: .25,
            },
            columnStyles: [
              {halign: "left" },
              {halign: "right" },
              {halign: "right" },
              {halign: "right" },
              {halign: "right" },
              {halign: "right" },
              {halign: "right" }
            ]
          });
        } else {
          doc.table(5, tableStart, pdfData, columns, {autoSize: true, fontSize: tableFontSize});
        }
        doc.save(`${values.id}-${values.report}-${values.dateStart}-${values.dateEnd}.pdf`);
      } else {
        setError(true)
        setDisabled(false);
      }
      setLoading(false);
    }
    
    return (
      <Container sx={style.mainContainer}>
          <Card 
              key={config.title}
              title={config.title}
              fields={config.fields}
              data={{}}
              values={values}
              setValues={setValues}
              validations={validations}
              setValidations={setValidations}
              bodyType={config.bodyType}
              setEdited={setEdited}
              checkListValues={{}}
          />
          {error &&
            <Typography sx={style.errorText}>
              Error Generating Report
            </Typography>
          }
          {edited &&
            <Button 
              sx={style.generateButton}
              variant='contained'
              disabled={disabled}
              onClick={() => handleGenerate()}>
                {loading ? "Generating..." : "Generate"}
            </Button>
          }
      </Container>
    )
}

export default ReportsPage;