import { DeleteTwoTone } from '@mui/icons-material';


export const getDetailViewConfig = async(data, theme) => {
    return {
        titleConfig: {
            breadcrumbs: true,
            field: '{firstName} {lastName}',
            mainAction: {
                type: 'text',
                text: 'Delete This User',
                field: 'delete',
                textColor: theme.palette.error.main,
                textStyles: ["underline"],
                preIcon: <DeleteTwoTone />,
                modalConfig: {
                    delete: {
                        title: 'Delete User',
                        type: 'text',
                        message: 'Are you sure you want to delete {data.firstName} {data.lastName} user?',
                        submitLabel: 'Delete',
                        cancelLabel: 'Cancel',
                        submitColor: theme.palette.error.main,
                        cancelColor: theme.palette.primary.main,
                        submitType: 'delete',
                    }
                }
            },
        },
        type: 'users',
        cards: [
            {
                column: 1,
                title: "User Information",
                bodyType: "form",
                fields: [
                    {
                        field: 'firstName',
                        headerName: 'First Name',
                        cellType: 'text',
                        required: true,
                        type: 'text',
                        dataType: 'string',
                    },
                    {
                        field: 'lastName',
                        headerName: 'Last Name',
                        cellType: 'text',
                        required: true,
                        type: 'text',
                        dataType: 'string',
                    },
                    {
                        field: 'email',
                        headerName: 'Email',
                        cellType: 'text',
                        disabled: true,
                        required: true,
                        type: 'text',
                        dataType: 'email',
                    },
                    {
                        field: 'role',
                        headerName: 'Role',
                        disabled: true,
                        required: true,
                        type: 'text',
                        dataType: 'string',
                    }
                ]
            }
        ]
    }
}