import * as XLSX from 'xlsx';

export const getDateAtTime = (date) => {
    date = date.replace("Z", "");
    const localTomezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateStr = new Date(date + "+0000").toLocaleString('en-US', {timeZone: localTomezone}).split(",");
    return `${dateStr[0]} at ${dateStr[1]}`
}

export const getTitleString = (title, plural=false) => {
    if (plural) {
        const titleString = title.charAt(0).toUpperCase() + title.substring(1, title.length - 1);
        if (titleString.charAt(titleString.length - 1) === 's') {
            return titleString;
        } else {
            return titleString + 's';
        
        }
    } else if (title.charAt(title.length - 1) === 's'){
            return title.charAt(0).toUpperCase() + title.substring(1, title.length - 1);
    } else {
        return title.charAt(0).toUpperCase() + title.substring(1, title.length);
    }
}

export const handlePhoneNumber = (newChar, value, field, changeType, values, validations) => {
    if (
        (!isNaN(newChar) || changeType === "remove") && ((changeType === "add" && value.length <= 13) || changeType === "remove")
      ) {
      if (value.length === 3) {
        if (changeType === "add") {
          value = `(${value})`;
        }
      }
      if (value.length === 4) {
        if (changeType === "remove") {
          value = value.slice(1, 3);
        }
      }
      if (value.length === 5) {
        if (changeType === "remove") {
          value = value.slice(1, 3);
        }
      }
      if (value.length === 8) {
        if (changeType === "add") {
          value = `${value}-`;
        } else {
          value = value.slice(0, 7);
        }
      }
      if (values) {
        values[field] = value;
      }
    }
    if (value.length >= 13) {
        if (value.length > 13) {
            value = value.slice(0, 13);
        }
        if (typeof(validations) === "object" && validations) {
            validations[field] = true;
        } else {
            validations = true;
        }
    } else {
        if (typeof(validations) === "object" && validations) {
            validations[field] = false;
        } else {
            validations = false;
        }
    }
    return {validations, values, value};
}

export const handleEmail = (value, field, validations, values) => {
    const emailRegex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (emailRegex.test(value)) {
        if (typeof(validations) === "object" && validations) {
            validations[field] = true;
        } else {
            validations = true;
        }
        if (values) {
            values[field] = value;
        }
    } else {
        if (typeof(validations) === "object" && validations) {
            validations[field] = false;
        } else {
            validations = false;
        }
        if (values) {
            values[field] = value;
          }
    }
    return {validations, values, value};
}

export const handleValidations = (columnData, value) => {
    let validation = false
    if (
        columnData.required &&
        value !== '' &&
        value !== null &&
        value !== undefined
      ) {
        if (columnData.dataType === "string" || columnData.dataType === "password") {
            validation = true;
        } else if (columnData.dataType === "email") {
            const response = handleEmail(value, columnData.field, validation, null);
            validation = response.validations;
        } else if (columnData.dataType === "phone") {
            const response = handlePhoneNumber(1, value, columnData.field, "add", null, validation);
            validation = response.validations;
        }
    }
    return validation;
}

export const handleValues = (columnData, value, values, newChar, changeType) => {
    if (
        columnData.required &&
        value !== '' &&
        value !== null &&
        value !== undefined
      ) {
        if (columnData.dataType === "string" || columnData.dataType === "password") {
            values[columnData.field] = value;
        } else if (columnData.dataType === "email") {
            const response = handleEmail(value, columnData.field, null, values);
            value = response.value;
        } else if (columnData.dataType === "phone") {
            const response = handlePhoneNumber(newChar, value, columnData.field, changeType, values, null);
            value = response.value;
        }
    } else {
      values[columnData.field] = value;
    }
    return {values, value};
}


export const handlePasswordValidation = (value, type, passRequirements, setPassRequirements, password) => {
    let tempPassRequirements = passRequirements;
    if (Object.values(tempPassRequirements).includes("init")) {
        tempPassRequirements = {length: false, upper: false, lower: false, number: false, special: false, match: false}
        setPassRequirements({...tempPassRequirements});
    }
    if (type === "new") {
        if (value.length >= 8) {
            tempPassRequirements['length'] = true;
        } else {
            tempPassRequirements['length'] = false;
        }

        if (value.match(/[A-Z]/)) {
            tempPassRequirements['upper'] = true;
        }
        else {
            tempPassRequirements['upper'] = false;
        }

        if (value.match(/[a-z]/)) {
            tempPassRequirements['lower'] = true;
        }
        else {
            tempPassRequirements['lower'] = false;
        }

        if (value.match(/[0-9]/)) {
            tempPassRequirements['number'] = true;
        }
        else {
            tempPassRequirements['number'] = false;
        }

        if (value.match(/[!@#$%^&*]/)) {
            tempPassRequirements['special'] = true;
        }
        else {
            tempPassRequirements['special'] = false;
        }
    } else {
        if (value === password) {
            tempPassRequirements['match'] = true;
        } else {
            tempPassRequirements['match'] = false;
        }
    }
    setPassRequirements({...tempPassRequirements});
}

export const PLColumnNames = [
    "Ticker",
    "Market Value",
    "Stock Return (%)",
    "Option Yield (%)",
    "Total Performance (%)",
    "Stock Volatility (%)",
    "Position Volatility (%)"
];

export const positionColumnNames = [
    "Business Date",
    "Ticker Symbol",
    "CUSIP",
    "Prod Code",
    "Options Display Symbol",
    "Underlying Ticker Symbol",
    "Underlyng CUSIP",
    "Current Quantity",
    "Trans Code",
    "Current Market Value",
    "Acquired Date",
    "Org Purchase Price",
];

export const transactionColumnNames = [
    "Business Date",
    "Ticker Symbol",
    "CUSIP",
    "Prod Code",
    "Options Display Symbol",
    "Underlying Ticker Symbol",
    "Underlyng CUSIP",
    "Tran SrCde",
    "Trade TypCd",
    "Quantity",
    "Price",
    "Gross Amount",
    "Net Amount",
    "Commission",
    "Debit Credit"
];

export const getJsonDataFromFile = (e, name) => {
    const fileType = name.split('.')[1];
    const finalData = []
    let columns = []
    let data = []
    let rawData = []
    if (fileType === "xlsx") {
        rawData = e.target.result;
        let readedData = XLSX.read(rawData, {type: 'binary'});
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
        columns = dataParse[0];
        const bodyData = dataParse.slice(1)
        for (var row1 in bodyData){
            data.push(bodyData[row1]);
        }
        
    }
    if (fileType === "csv") {
        rawData = e.target.result
        var splitData = rawData.split(/\r?\n/);
        columns = splitData[0];
        const bodyData = splitData.slice(1)
        for (var row2 in bodyData){
            data.push(bodyData[row2].split(','));
        }
    }
    columns = ["id", "amount", "exDate"]
    const rows = data.slice(1);
    for (let row in rows) {
        let tempRow = {};
        for (let column in columns) {
            let cellValue = rows[row][columns.indexOf(columns[column])]
            if (columns[column] === "exDate") {
                var utc_days  = Math.floor(cellValue - 25569);
                var utc_value = utc_days * 86400;                                        
                var date_info = new Date(utc_value * 1000);
                cellValue = date_info.toISOString().split('T')[0]
            } else if (columns[column] === "amount"){
                cellValue = Math.round(cellValue * 100) / 100
            }
            tempRow[columns[column]] = cellValue && String(cellValue).trim() !== "" ? String(cellValue).trim() : null;
        }
        finalData.push(tempRow);
    }
    return finalData;
}