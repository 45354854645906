export const styles = (theme) => {
    return {
        loginFormWrapper: {
            width: '430px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        loginTitle: {
            fontSize: '35px',
            fontWeight: '700',
            lineHeight: '38px',
            letterSpacing: '0em',
            marginBottom: '10px',
            color: theme.palette.secondary.main,
        },
        loginSubTitle: {
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '38px',
            letterSpacing: '0em',
            textAlign: 'center',
            color: theme.palette.secondary.main,
        },
        loginForm: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '0px',
            padding: '0px !important',
            minWidth: '100%',
        },
        loginInputLabel: {
            flex: '1',
            display: 'flex',
            alignSelf: 'flex-start',
            marginTop: '10px',
            marginBottom: '5px',
            fontSize: '14px',
            color: theme.palette.custom.white,
        },
        loginInputTextbox: {
            margin: '0px',
            minWidth: '100%',
            padding: '10px',
            marginBottom: '5px',
            borderRadius: '5px',
            fontSize: '18px',
            webkitAutofill: "none !important",
            WebkitBoxShadow: "none !important",
            backgroundColor: theme.palette.custom.white,
            '& .MuiOutlinedInput-input': {
                padding: '3px 30px 3px 0px !important',
                border: 'none !important',
                color: theme.palette.secondary.main,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                margin: '0px',
                border: 'none !important',
            },
            '&.Mui-focused .MuiOutlinedInput-input': {
                margin: '0px',
                border: 'none !important',
                padding: '3px 30px 3px 0px !important',
            },
        },
        signInButton: {
            marginTop: '20px',
            marginBottom: '20px',
            minWidth: '80%',
            padding: '10px',
            borderRadius: '5px',
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.custom.white,
            fontSize: '18px',
            fontWeight: '600',
            cursor: 'pointer',
        },
        visibilityIcon: {
            cursor: 'pointer',
            width: '20px',
        },
        warningText: {
            margin: '0px',
            padding: '0px !important',
            color: theme.palette.error.main,
            fontSize: '14px',
        },
        loginForgotPassword: {
            textDecoration: 'underline',
            fontSize: '16px',
            color: theme.palette.secondary.main,
            cursor: 'pointer',
        },
    }
};