export const styles = (theme) => {
    return {
        detailViewHeaderContainer: {
            margin: '0px',
            paddingTop: '30px',
            paddingBottom: '10px',
            minWidth: '100%',
        },
        detailViewHeaderRightButton: {
            margin: '0px',
            borderRadius: '8px',
            height: '50px',
            paddingRight: '20px',
            paddingLeft: '20px',
            fontSize: '20px',
            fontWeight: '600',
            fontFamily: 'Nunito Sans',
            paddingTop: 'auto',
            paddingBottom: 'auto',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
            },
        },
        detailViewHeaderRightButtonAfterIcon: {
            marginLeft: '12px',
            marginTop: '5px',
            marginBottom: '-5px',
        },
        detailViewHeaderActionsContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: 'calc(100% - 120px)',
            margin: '0px',
            marginLeft: '60px',
            marginTop: '20px',
            marginBottom: '0px',
        },
        detailViewHeaderRightButtonAfterContainer: {
            display: 'flex',
            flexDirection: 'row',
            width: 'auto',
        },
        detailViewHeaderRightButtonAfterProgress: {
            margin: '0px',
            marginTop: '7px',
            marginRight: '10px',
        },
        breadcrumbReplacement: {
            fontSize: '20px',
            fontWeight: '400',
            color: theme.palette.primary.main,
            marginTop: '20px',
            marginBottom: '10px',
            marginLeft: '60px',
        },
    };
};
