export const populateTheme = (colors) => {
    const customColors = {}
    for (const key in colors.custom) {
      customColors[key] = colors.custom[key]
    }
    return{
        palette: {
            primary: { main: colors.primary.main, dark: colors.primary.dark, light: colors.primary.light},
            secondary: { main: colors.secondary.main, dark: colors.secondary.dark, light: colors.secondary.light},
            error: { main: colors.error.main, dark: colors.error.dark, light: colors.error.light},
            warning: { main: colors.warning.main, dark: colors.warning.dark, light: colors.warning.light},
            info: { main: colors.info.main, dark: colors.info.dark, light: colors.info.light},
            success: { main: colors.success.main, dark: colors.success.dark, light: colors.success.light},
            custom: customColors,
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: `
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
              -webkit-box-shadow: 0 0 0 30px ${customColors.white} inset !important;
            }`,
          },
          MuiTextField: {
            styleOverrides: {
                root: {
                backgroundColor: customColors.white,
                '& .MuiInput-root': { marginTop: 24 },
                },
            },
            defaultProps: {
              FormHelperTextProps: {
                sx: {
                  fontSize: '0.75rem',
                  fontFamily: 'Nunito Sans',
                  lineHeight: '1rem',
                  letterSpacing: '0.025rem',
                },
              },
              InputLabelProps: {
                shrink: true,
                sx: {
                  fontSize: '0.875rem',
                  fontFamily: 'Nunito Sans',
                  lineHeight: '1.5rem',
                  letterSpacing: '0.031rem',
                  transform: 'translate(0px, 0px) scale(1)',
                },
              },
              InputProps: {
                sx: {
                  fontSize: '0.875rem',
                  fontFamily: 'Nunito Sans',
                  lineHeight: '1.25rem',
                  letterSpacing: '0.016rem',
                },
              },
              inputProps: {
                style: {
                  padding: '10px 10px',
                },
              },
            },
          },
          MuiInput: {
            defaultProps: {
              sx: {
                fontSize: '0.875rem',
                fontFamily: 'Nunito Sans',
                lineHeight: '1.25rem',
                letterSpacing: '0.016rem',
              },
              inputProps: {
                style: {
                  padding: '10px 2px',
                },
              },
            },
          },
          MuiInputLabel: {
            defaultProps: {
              sx: {
                fontSize: '0.875rem',
                fontFamily: 'Nunito Sans',
                lineHeight: '1.5rem',
                letterSpacing: '0.031rem',
                transform: 'translate(0px, 0px) scale(1)',
              },
            },
          },
          MuiFormLabel: {
            defaultProps: {
              sx: {
                fontSize: '0.875rem',
                fontFamily: 'Nunito Sans',
                lineHeight: '1.5rem',
                letterSpacing: '0.031rem',
                color: 'rgba(255, 255, 255, 0.75)',
              },
            },
          },
          MuiAutocomplete: {
            root: {
                backgroundColor: 'red',
            },
            defaultProps: {
              sx: {
                fontSize: '0.875rem',
                fontFamily: 'Nunito Sans',
                lineHeight: '1.25rem',
                letterSpacing: '0.016rem',
                color: 'rgba(255, 255, 255, 0.75) !important',
              },
            },
          },
          MuiFormHelperText: {
            defaultProps: {
              sx: {
                fontSize: '0.75rem',
                fontFamily: 'Nunito Sans',
                lineHeight: '1rem',
                letterSpacing: '0.025rem',
                color: 'rgba(255, 255, 255, 0.75)',
              },
            },
          },
          MuiSlider: {
            styleOverrides: {
              thumb: {
                color: 'rgba(147, 130, 255, 1)',
              },
              track: {
                color: 'rgba(147, 130, 255, 1)',
              },
              rail: {
                color: 'rgba(255, 255, 255, 0.2)',
              },
              valueLabel: {
                fontWeight: 500,
                fontsize: '0.625rem',
                lineHeight: '1rem',
                letterSpacing: '0.094rem',
                color: 'rgba(25, 26, 30, 1)',
                background: 'rgba(255, 255, 255, 0.87)',
                padding: '4px 18px',
                '::before': {
                  background: '#e4e4e5',
                },
              },
            },
          },
          MuiContainer: {
              root: {
                padding: '0px',
                margin: '0px !important',
              },
          },
        }
      };
}