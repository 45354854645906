import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { styles } from "../../styles/cards/cardTables";
import { Edit, Delete, Add} from '@mui/icons-material';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Container,
  Typography
} from "@mui/material";
import ModalMD from '../../modalMD';
import { api } from "../../../../api/index";

const BodyTable = ({ data, columns, apiBody, apiType, modalConfigs, values, setValues, setEdited, validations, setValidations }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const theme = useTheme();
  const style = styles(theme);
  const [dataVar, setDataVar] = useState([]);
  const [allData, setAllData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfig, setmodalConfig] = useState({});
  const [filterData, setFilterData] = useState({});
  const [searchvalue, setSearchValue] = useState("");

  const processFilter = async (fields, values) => {
      setSearchValue(values)
      const tempFilterData = filterData;
      if (fields && values) {
        for (let field in fields) {
          if (
            !values ||
            values.includes("All") ||
            values.length === 0 ||
            values === ""
          ) {
            delete tempFilterData[fields[field]];
          } else {
            tempFilterData[fields[field]] = values;
          }
          setFilterData(tempFilterData);
        }
      } else {
        if (fields) {
          for (let field in fields) {
            delete tempFilterData[fields[field]];
          }
        }
      }
      const filteredData = []
      for (let item in allData) {
          let foundInSearch = false
          for (let filter in tempFilterData) {
              if (allData[item][filter]){
                  const tempItem = allData[item][filter].toString()
                  if (tempItem.constructor === Array) {
                      if (tempFilterData[filter].some(r=> [item][filter].some(f => f.substring(0, r.length).toLowerCase() === r.toLowerCase()))) {
                          foundInSearch = true
                      }
                  } else if (tempItem.substring(0, tempFilterData[filter].length).toLowerCase() === tempFilterData[filter].toLowerCase()){
                      foundInSearch = true
                  }
              }
          }
          if (foundInSearch) {
              filteredData.push(allData[item])
          }
      }
      if (Object.keys(tempFilterData).length === 0) {
        setDataVar([...allData]);
      } else {
        setDataVar([...filteredData]);
      }
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await api("get", apiBody, apiType, state, navigate);
      if (response.status === 200) {
        setDataVar(response.data);
        setAllData(response.data)
      } else {

      }

    };
    if (data){
      const tempData = []
      for (let ticker in data.tickers){
        tempData.push(data.tickers[ticker])
      }
      const sortedData = tempData.sort((a, b) => {
        return a["ticker"] > b["ticker"] ? 1 : -1;
      });
      setDataVar(sortedData);
      setAllData(sortedData)
    } else {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  const handleModalOpen = (modalConfigObj, value) => {
      if (value){
        modalConfigObj.value = value;
      }
      setmodalConfig(modalConfigObj);
      setModalOpen(modalConfigObj.title);
  }

  const handleSaveModal = (value) => {
    if (modalConfig.submitType === "add" || modalConfig.submitType === "patch"){
      if (values.tickers) {
        values.tickers[value.cusip] = value;
      } else {
        values.tickers = {}
        values.tickers[value.cusip] = value;
      }
      
    }
    if (modalConfig.submitType === "delete"){
      delete values.tickers[value.cusip];
    }
    setValues({...values});
    const tempDataVar = []
    for (let value in values.tickers){
      tempDataVar.push(values.tickers[value])
    }
    setDataVar([...tempDataVar])
    setEdited(true);
  }

  const handleModalSubmit = async (value) => {
    await handleSaveModal(value);
    setModalOpen(false);
  }

  return (
    <TableContainer sx={style.tableContainer}>
      <Container sx={style.tableActions}>
        <Add sx={style.addButton} onClick={() => handleModalOpen(modalConfigs.add, null)}/>
        <TextField
            sx={style.textBox}
            value={searchvalue}
            onChange={(e) => processFilter(["cusip", "ticker", "managed"], e.target.value)}
            placeholder="Search"
        />
      </Container>
      {(dataVar && dataVar.length) ? (
        <Table sx={style.table}>
          <TableHead sx={style.tableHead}>
            <TableRow sx={style.tableHeadRow}>
              {columns.map((column, index) => (
                <TableCell key={index} sx={style.tableHeadCell}>{column.title}</TableCell>
              ))}
              <TableCell sx={style.tableHeadCell}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={style.tableBody}>
            {dataVar.map((item, index) => (
              <TableRow key={index} sx={style.tableBodyRow}>
                {columns.map((column, indexCell) => (
                  <TableCell key={indexCell} sx={style.tableBodyCell}>
                    {(item[column.field] !== true && item[column.field] !== false) && item[column.field]}
                    {item[column.field] === true && "Yes"}
                    {item[column.field] === false && "No"}
                    </TableCell>
                ))}
                <TableCell sx={style.tableBodyCell}>
                  <Edit onClick={() => handleModalOpen(modalConfigs.edit, item)}/>
                  <Delete onClick={() => handleModalOpen(modalConfigs.delete, item)}/>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography>
          No data to display.
        </Typography>
      )}
      <ModalMD 
          title={modalConfig.title}
          type={modalConfig.type}
          label={modalConfig.label}
          cancelLabel={modalConfig.cancelLabel}
          submitLabel={modalConfig.submitLabel}
          submittingLabel={modalConfig.submittingLabel}
          open={modalOpen}
          submitFunction={handleModalSubmit}
          message={modalConfig.message}
          data={dataVar}
          setModalOpen={setModalOpen}
          formfields={modalConfig.form?.fields}
          thisValue={modalConfig.value}
          submitType={modalConfig.submitType}
      />
    </TableContainer>
  );
};

export default BodyTable;
