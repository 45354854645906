export const styles = (theme) => {
    return {
        tableContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: 'calc(100%)',
            marginTop: '10px',
            paddingTop: '20px !important',
            height: "40Vh !important",
        },
        tableActions: {
            display: "flex",
            flexDirection: "row",
            justifyContent: 'space-between',
            alignItems: "center"
        },
        addButton: {
            color: theme.palette.success.main
        },
        textBox: {
            width: "50%"
        },
        table: {
        },
        tableHead: {

        },
        tableHeadRow: {

        },
        tableHeadCell: {
            
        },
        tableBody: {

        },
        tableBodyRow: {

        },
        tableBodyCell: {

        }
    };
};