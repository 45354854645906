export const styles = (theme) => {
    return {
        DataGridFiltersContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            margin: '20px 0px 0px 0px',
            backgroundColor: '#fff',
            alignItems: "center",
            minWidth: '100%',
            borderRadius: '4px 4px 0px 0px',
            border: '1px solid #F0F0F0',
            borderBottom: "none",
        },
        DataGridFiltersSelect: {
            marginTop: '10px',
            marginBottom: '10px',
            width: '200px',
            '& .MuiSelect-select': {
            },
            '& .MuiOutlinedInput-notchedOutline': {
                color: '#000',
                border: '1px solid #F0F0F0',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                color: '#000',
                border: '1px solid #F0F0F0',
            },
            '& .MuiInputLabel-root': {
                color: '#000',
            },
        },
        DataGridFilterIcon: {
            width: '25px',
            height: '25x',
            marginRight: '15px',
            marginLeft: '15px',
        },
        DataGridFilterInputLabel: {
            color: '#000',
            '&.Mui-focused': {
                color: '#000',
            },
        },
        DataGridFilterFormControl: {
            display: 'flex',
            marginLeft: '20px',
        },
    };
};
