import React from 'react';

const ErrorFourOFour = (props) => {
    return (
        <div>
            404 Not Found
        </div>
    );
};

export default ErrorFourOFour;