export const getPermissionOptions = async () => {
    const permissionOptions = [
        {
            label: "Admin",
            value: "admin"
        },
        {
            label: "Advisor",
            value: "advisor"
        }
    ]
    return permissionOptions;
}

export const getAdvisors = async (users) => {
    const advisors = []
    for (let user of users) {
        if (user.role === "advisor") {
            advisors.push({
                label: user.firstName + " " + user.lastName,
                value: user.id
            })
        }
    }
    return advisors;
}
