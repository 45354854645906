import React from 'react';
import { useEffect, useState} from 'react';
import DataTableMD from '../core/dataTableMD';
import { getDividendColumns, getModalConfig } from './configs/dividendDataTableConfig';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const DividendsPage = (props) => {
    const theme = useTheme();
    let { state } = useLocation()
    const [columns, setColumns] = useState([])
    const [modalConfigObj, setModalConfigObj] = useState({})
    useEffect(() => {
        const getFilters = async () => {
            const columns = await getDividendColumns(state, theme)
            setColumns(columns)
            const modalConfig = await getModalConfig(theme)
            setModalConfigObj(modalConfig)
        }
        getFilters()
        // eslint-disable-next-line
    }, [])
    
    const addSuccessMessage = "The new dividend has been successfully added to the system. "
    return (
        <DataTableMD
            title="Dividends"
            filters = {{}}
            search={{fields: ["id", "amount", "exDate"]}}
            columns = {columns}
            addButton={true}
            addSuccessMessage={addSuccessMessage}
            sort={{field: 'ticker', order: 'asc'}}
            bulkAddButton={true}
            modalConfigObj={modalConfigObj}
        />
    );
};

export default DividendsPage;