import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Card from '../core/components/cards/card';
import { getCardConfig } from './configs/accountMasterConfigs';
import { api } from '../../api/index';

const AccountMasterFilePage = () => {
    const navigate = useNavigate()
    let { state } = useLocation();
    const theme = useTheme();
    const defaultValues = {id : ""};
    const defaultValidations = {id : false};

    const [values, setValues] = useState(defaultValues);
    const [validations, setValidations] = useState(defaultValidations);
    const [edited, setEdited] = useState(false);
    const [config, setConfig] = useState({});
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const config = await getCardConfig(state);
            setConfig(config);
        }
        fetchData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
      const disabled = Object.values(validations).includes(false);
      setDisabled(disabled);
      // eslint-disable-next-line
    }, [validations]);


    const handleExport = async () => {
        setErrorMsg('')
        setLoading(true);
        setError(false)
        const results = await api("get", {}, "Accounts", state, navigate);
        //const results = await api("post", values, "masterexport", state, navigate);
        if (results.status === 200) {
            if(results.data.length > 0){
              const flattenData = convertData(results.data)
                const csv = convertToCSV(flattenData)
                const date = new Date(Date.now())
                downloadCSV(csv, `Master Account File - ${date.toLocaleDateString()}`)
            }
            else {
                setErrorMsg(' - No Data found for selected Account')
                setError(true)
                setDisabled(false);
            }
        } else {
            setError(true)
            setDisabled(false);
        }
        setLoading(false);
    }

    const convertData = (data) => {
      let result = [];
      data.forEach(item => {
        let account = {}
        account["id"] = item.id
        account["name"] = item.name
        account["advisorName"] = item.advisorName
        account["startDate"] = item.startDate
        result.push(account)
        const label = {
          '1' : 'Ticker',
          '2' : 'cusip',
          '3' : 'managed'
        }
        result.push(label)
        for (const key in item.tickers) {
          const ticker = item.tickers[key]
          let tickerData = {}
          tickerData["ticker"] = ticker.ticker
          tickerData["cusip"] = ticker.cusip
          tickerData["managed"] = (ticker.managed === true || ticker.managed === 'true') ?? 'Yes' | 'No'
          result.push(tickerData)
        }
      });
      return result;
    };

    const convertToCSV = (data) => {
      const array = [Object.keys(data[0])].concat(data);
    
      return array.map(row => {
        return Object.values(row).map(value => {
          return typeof value === 'string' ? JSON.stringify(value) : value;
        }).join(',');
      }).join('\n');
    };
  
      const downloadCSV = (csv, filename) => {
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      };
    
    return (
      <Container sx={{display : 'flex', flexDirection : 'column', justifyContent : 'center', alignItems : 'center', height : '100vh', maxWidth : '600px !important'}}>
          <Card 
              key={config.title}
              title={config.title}
              fields={config.fields}
              data={{}}
              values={values}
              setValues={setValues}
              validations={validations}
              setValidations={setValidations}
              bodyType={config.bodyType}
              setEdited={setEdited}
              checkListValues={{}}
          />
          {error &&
            <Typography sx={{color : theme.palette.warning.main}}>
              Error Exporting File {errorMsg}
            </Typography>
          }
          
            <Button 
              variant='contained'
              onClick={() => handleExport()}>
                {loading ? "Exporting..." : "Export"}
            </Button>
          
      </Container>
    )
}

export default AccountMasterFilePage;