import React from 'react'
import DefaultLayout from '../layouts/defaultLayout';
import Reports from '../feature/reports/reports';
import Users from '../feature/users/users';
import User from '../feature/users/user';
import Profile from '../feature/profile/profile';
import Accounts from '../feature/accounts/accounts';
import Account from '../feature/accounts/account';
import Dividends from '../feature/dividends/dividends';
import Dividend from '../feature/dividends/dividend';
import Data from '../feature/data/data';

import Error404 from '../feature/core/error404';
import PositionFilePage from '../feature/positionFile/positionFile';
import AccountMasterFilePage from '../feature/accountMasterFile/accountMasterFile';

const privateRoutes = (setThemeName) => {
 return [
    {
      path: "/",
      element: <DefaultLayout setThemeName={setThemeName}/>,
      children: [
        {index: true, element: <Reports />},
        {path: 'reports', element: <Reports />},
        {path: 'users', element: <Users />},
        {path: 'user/:id', element: <User />},
        {path: 'profile', element: <Profile />},
        {path: 'data', element: <Data />},
        {path: 'accounts', element: <Accounts />},
        {path: 'account/:id', element: <Account />},
        {path: 'dividends', element: <Dividends />},
        {path: 'dividend/:id', element: <Dividend />},
        {path: 'positionFile', element: <PositionFilePage />},
        {path: 'masterFile', element: <AccountMasterFilePage />},
        {path: "*", element: <Error404 /> },

      ],
    }
 ];
}

export default privateRoutes;